.testimonials {
    &.featureBlock--single {
        .row {
            .column {
                justify-content: center;
            }
        }
    }
    .column {
        display: flex;
        .featureBlock-figure {
            background-color: $colorWhite;
            border: 1px solid $colorBrandPrimary;
            display: flex;
            padding: $spacingSm;
            border-radius: $borderRadius;
            .featureBlock-figcaption {
                display: flex;
                flex-direction: column;
                .featureBlock-subHeading {
                    font-size: 1em;
                    span {
                        display: block;
                    }
                }
                p:first-of-type {
                    flex: 1;
                    display: flex;
                    align-items: center;
                }
                .date-rating {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }
    color: $colorText !important;
}