/* TRANSITION
========================================================================== */
/*
* generic transition effect
*
* USAGE: @include transition(all 0.2s ease-in-out);
*/

@mixin transition($transition...) {
  $prefixes: "-webkit", "-ms", "-o", "";
  @each $prefix in $prefixes {
    #{$prefix}-transition: $transition;
  }
  transition: $transition;
  /*
  * force ios to use the gpu to smooth out transitions. May be really choppy otherwise
  */
  -webkit-transform: translateZ(0);
}
