/* BUG
========================================================================== */

/*
* Indicator bug. Number of messages, alerts, etc...
*/
.eventDetail {
}
.eventDetail-image {
    @include mq(med) {
        float:right;
        margin-left:$spacingBase;
        max-width:40%;
    }
    img {
        border-radius: $borderRadius;
    }
}
.eventDetail-body {
     text-align:left;
     margin-bottom:$spacingBase;
     overflow:hidden;
     // style headings
     table {
        margin-bottom:$spacingBase;
        vertical-align:top;
        tr {
            vertical-align:top;
        }
         th {
            padding-right:$spacingBase;
            // allow for consistent character 
            &:after {
                content: ':';
                padding:0 2px
            }
         }        
     }
     .eventDetail-CTAs {
            @extend .list--bare;
            @extend .list--horizontal;
            a {
                @extend .btn;
            }
     }
     // style Locoation Name and Address
     .eventDetail-locationDetail {
        // specific syntax for address listing
            address {
                margin:0;
                // controls address line-by-line
                span {}
            }
            ul {
                @extend .list--bare;
                @extend .list--horizontal;
                @extend .list--separated;
                margin:0;
            }
     }
}
// control specific styles for Event Detail body here
 .eventDetail-bodyContent {
    border-top:1px solid $colorBorder;
    clear:both;
    h2, h3, h4, h5 {
        margin-bottom:$spacingXSm
    }
 }

 .newsDetail-infoList {
    @extend .infoList;
 }
 .newsDetail-image {
    float:right;
    margin-left:$spacingBase;
    img {
        border-radius: $borderRadius;
    }
 }