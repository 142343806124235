/* NAV MAIN
========================================================================== */

.navMain {
    //display:none;
    @include font-size(16px);
    @include align-self(stretch);
    @include mq(lg) {
       @include flexbox;
    }
    ul {
       margin-bottom: $spacingSm;
       padding: 0;
 
       list-style: none;
    }
    > ul {
       @extend %flexbox;
       @extend %contained;
       > li {
          @extend %flexbox;
          @include align-items(center);
          @include flex(1 1 auto);
          position: relative;
          > span, > a {
             position: relative;
             z-index: 1;
 
             display: block;
             overflow: hidden;
 
             padding: $spacingXSm $spacingSm;
 
             cursor: pointer;
             text-align: center;
             text-decoration: none;
             font-weight: bold;
 
             text-transform: none;
 
             &:before {
                @include transition(all .5s ease-in-out);
                position: absolute;
                z-index: -1;
                top: 50%;
                left: 50%;
 
                width: 5px;
                height: 5px;
                margin-left: -2.5px;
                margin-left: -2.5px;
 
                content: '';
 
                opacity: 0;
                border-radius: 500px;
                background-color: lighten($colorGrey,7);
             }
          }
          > ul {
             @include transition(all .2s ease-in-out);
             @include flex-direction(column);
             position: absolute;
             z-index: 10;
             top: 100%;
             // top: 70%;
             right: 0;
 
             min-width: 200px;
             padding: $spacingSm;
 
             transform: scale(1,0);
             transform-origin: top;
 
             background: $colorWhite;
 
             border: 1px solid rgba(0,0,0,0.15);
             border-radius: 4px;
             -webkit-box-shadow: 0 6px 12px rgba(0,0,0,0.175);
             box-shadow: 0 6px 12px rgba(0,0,0,0.175);
 
             a {
                @include transition(all .25s ease-in-out);
                display: block;
 
                padding: 0 $spacingXSm;
 
                text-decoration: none;
                font-weight: 400;
 
                color: #4d4d4d;
                &:hover {
                   background: #e8e8e8;
                }
             }
             &.pullLeft {
                right: 0;
                left: auto;
             }
          }
          &:hover {
             > span, > a {
             //    color: $colorWhite;
                &:before {
                   transform: scale(50);
 
                   opacity: 1;
                   background: #e7e7e7;
                }
             }
             > ul {
                -ms-transform: scale(1,1);
                -webkit-transform: scale(1,1);
                transform: scale(1,1);
             }
          }
          &.selected {
             > span, > a {
                color: $colorWhite;
                background: $colorBrandSecondary;
             }
          }
          &.is-mega {
             position: static;
             > ul {
                @extend %flexbox;
                @include flex-direction(row);
                @include justify-content(center);
                left: 0;
                > li {
                   @include flex(1 1 0);
                   //for IE11
                   width: 100%;
                   max-width: 250px;
                   padding-left: $spacingSm;
                   &:first-child {
                      padding: 0;
                   }
                   > a {
                      margin-bottom: $spacingXSm;
 
                      border-bottom: 1px solid $colorWhite;
                   }
                }
             }
          }
          &.hasChildren {
             > a {
                &:after {
                   display: inline-block;
 
                   width: 0;
                   height: 0;
                   margin-left: $spacingXSm;
 
                   content: '';
                   vertical-align: middle;
 
                   border-top: 4px solid;
                   border-right: 4px solid transparent;
                   border-left: 4px solid transparent;
                }
             }
          }
       }
    }
 }
 
 //Hide menu items when the nav becomes hidden
//  body.js-headerFixed--up {
//     .navMain li.hasChildren > ul {
//        transform: scale(1,0) !important;
//     }
//  }
 
 .rail-links {
    ul {
        list-style: none;
        padding-left: 0;
        li {
            a {
                @include font-size(18px);
            }
        }
    }
 }

 .hide-me {
    display: none;
 }