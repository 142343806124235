/*  FONT FAMILIES
========================================================================== */

/*
 * Apply font families to text
 */

.h-fontHighlight {
    font-family: $fontHighlight !important;
}

.h-fontBase {
    font-family: $fontDefault !important;
}
