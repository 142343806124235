.ep-job-seekers-employers {
    margin-inline: 0;
    .column {
        padding-inline: 0;
        &:first-of-type {
            padding-right: 1px;
        }
        &:last-of-type {
            padding-left: 1px;
        }
        &:not(:first-of-type) {
            margin-top: $spacingBase;
            @include mq(med) {
                margin-top: 0;
            }
        }
        @include mq(med) {
            flex: 1;
            display: flex;
        }
        .ep-job-seekers, .ep-employers {
            border: 1px solid $colorGrey;
            display: flex;
            flex-direction: column;
            flex: 1;
            position: relative;
            .image-container {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 0;
                }
            }
            .description {
                z-index: 2;
                padding: $spacingBase;
                display: flex;
                flex-direction: column;
                flex: 1;
                .label {
                    font-weight: bold;
                }
                h2 {
                    text-transform: uppercase;
                    .h-h1 {
                        display: block;
                        margin-block: 0;
                    }
                    .h-h4 {
                        margin-block: 0;
                    }
                    .subtitle {
                        @include font-size(23px);
                    }
                    .title {
                        @include font-size(38px);
                    }
                }
                .flex-grow {
                    flex: 1;
                }
                .search-container {
                    position: relative;
                    @include flexbox;
                    @include flex-direction(column);
                    @include mq(lg) {
                        @include flex-direction(row);
                    }
                    label {
                        display: block;
                    }
                    .input-container {
                        display: inline-block;
                        flex: 1 1 100%;
                        padding-block: $spacingSm;
                        @include mq(lg) {
                            padding-inline: $spacingSm;
                            flex: 1 0 35%;
                            padding-left: 0;
                        }
                        &.full-width {
                            width: 78%;
                        }
                        input {
                            @include font-size(16px);
                            height: 42px;
                            margin-bottom: 0;
                            border: 1px solid $colorGreyLight;
                            width: 100%;
                            color: $colorText;
                        }
                        &.radius-container {
                            @include mq(lg) {
                                flex: 1 0 15%;
                            }    
                            select {
                                @include font-size(16px);
                                height: 42px;
                                color: $colorText;
                                border-radius: $borderRadius;
                                margin-bottom: 0;
                                border: 1px solid $colorGreyLight;
                                @include mq(lg) {
                                    padding-left: $spacingSm;
                                }
                            }
                        }
                        p {
                            margin-bottom: 0;
                        }
                    }
                    > .button-container {
                        padding-block: $spacingSm;
                        @include mq(lg) {
                            flex: 0 0 15%;
                        }
                        display: inline-block;
                        padding-right: 0;
                        a {
                            position: static;
                            padding-inline: $spacingBase;
                            height: 41px;
                            width: 100%;
                        }
                    }
                }
                > .button-container {
                    padding-block: $spacingSm;
                }
                .btn {
                    font-weight: bold;
                }
            }
            &.textLight {
                color: $colorWhite;
                .label {
                    color: $colorWhite;
                }
                a {
                    color: $colorWhite;
                }
            }
            &.textDark {
                color: color-contrast($colorWhite);
                label {
                    color: color-contrast($colorWhite);
                }
                a {
                    color: color-contrast($colorWhite);
                }
            }
            &.textBrand {
                color: $colorBrandPrimary;
                .label {
                    color: $colorBrandPrimary;
                }
                a {
                    color: $colorBrandPrimary;
                }
            }
        }
    }
}