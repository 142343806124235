/* <ASIDE>
========================================================================== */

aside {
    float: none;

    margin-bottom: $lineHeight;
    padding: $spacingSm;

    background: $colorGreyLightest;
    border-radius: $borderRadius;
    p:last-of-type {
        margin-bottom: 0;
    }
}