.featureCard-selectors {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 0;
    margin-bottom: $spacingLg;
    .featureCard-selector {
        background-color: $colorWhite;
        border: none;
        display: inline;
        cursor: pointer;
        padding: $spacingBase;
        i {
            padding-right: $spacingSm;
        }
        &.active {
            border: 1px solid $colorBrandPrimary;
            // color: color-contrast($colorBrandPrimary);
        }
    }
}
.featureCard-container {
    display: flex;
    flex-direction: column;
    margin: 5em auto 0;
    > div {
        flex-grow: 0;
    }
    .tabs {
        position: relative;
        flex-grow: 1;
        @include mq(med) {
            min-height: 400px;
            height: 30vh;
        }
    }
    .transitions, .transitions .tab {
        display: flex;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
    .tab {
        display: none;
        height: 100%;
        align-items: stretch;
        perspective: 50cm;
        background-color: $colorWhite;
        // justify-content: center;
        &.active {
            display: flex;
            align-items: center;
        }
        .left, .right {
            flex: 1;
        }
    }
    .transitions {
        .tab {
            .left, .right {
                transform: rotateY(0);
                opacity: 1;
            }
            .left {
                transform-origin: center left;
            }
            .right {
                transform-origin: center right;
            }
            &.transitioning {
                .left, .right {
                    transition:
                    transform 1s ease-in-out,
                    opacity .5s ease-in .5s;
                    opacity: 0;
                }
                .left {
                    transform: rotateY(-179deg);
                }
                .right {
                    transform: rotateY(179deg);
                }
            }
        }
    }
}