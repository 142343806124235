@charset "UTF-8";
/* CSS Document */
.notification--success {
	background: #DB2526;
}
/*.btn {
	border: 1px solid #DB2526;
	background: #DB2526;
	margin: 5px;
	padding: 10px;
	border-radius: 10px;
	border: 1px solid #DB2526;
	background: #DB2526;
	border: 1px solid #DB2526;
	background: #DB2526;
	&:hover {
		background: #212121;
		border: 1px solid #000000;
	}
}*/
.formFooter {
	a {
		border: 1px solid #DB2526;
		background: #DB2526;
	}
}
.form-footer {
	a {
		border: 1px solid #DB2526;
		background: #DB2526;
	}
	:hover {
		border: 1px solid #DB2526;
		background: #DB2526;
	}
}
.socialBlock {
	background: #212121;
	border-top: 10px solid #DB2526;
}
.accordion {
	>li.is-active {
		span {
			color: #DB2526;
		}
	}
	>li {
		color: #DB2526;
		hover {
			color: #DB2526;
		}
	}
}
.logoMain {
	img {
        max-height: 48px;
        @include mq(med) {
    		max-height: 68px;
        }
	}
}

.sliderMainImage {
	background-position-y: 0 !important;
}
.sliderMainContent-heading {
	text-transform: none !important;
}
.lr-rails {
    margin-inline: 0;
    > .column {
        padding-inline: 0;
    }
    > .column.med-16 {
        padding-inline: $spacingBase;
        .video {
            margin-inline: -$spacingBase;
        }
    }
    .lr-rail {
        .section {
            height: 100%;
        }
    }
}