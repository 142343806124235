/*  COLORS
========================================================================== */

/*
 * Apply color to text
 */

 .h-colorBrandPrimary {
    color: $colorBrandPrimary !important;
}
.h-colorBrandPrimaryLight {
    color: $colorBrandPrimaryLight !important;
}
.h-colorBrandPrimaryLighter {
    color: $colorBrandPrimaryLighter !important;
}
.h-colorBrandPrimaryLightest {
    color: $colorBrandPrimaryLightest !important;
}
.h-colorBrandPrimaryDark {
    color: $colorBrandPrimaryDark !important;
}
.h-colorBrandPrimaryDarker {
    color: $colorBrandPrimaryDarker !important;
}
.h-colorBrandPrimaryDarkest {
    color: $colorBrandPrimaryDarkest !important;
}

.h-colorBrandSecondary {
    color: $colorBrandSecondary !important;
}
.h-colorBrandSecondaryLight {
    color: $colorBrandSecondaryLight !important;
}
.h-colorBrandSecondaryLighter {
    color: $colorBrandSecondaryLighter !important;
}
.h-colorBrandSecondaryLightest {
    color: $colorBrandSecondaryLightest !important;
}
.h-colorBrandSecondaryDark {
    color: $colorBrandSecondaryDark !important;
}
.h-colorBrandSecondaryDarker {
    color: $colorBrandSecondaryDarker !important;
}
.h-colorBrandSecondaryDarkest {
    color: $colorBrandSecondaryDarkest !important;
}

.h-colorAccent {
    color: $colorAccent !important;
}
.h-colorAccentLight {
    color: $colorAccentLight !important;
}
.h-colorAccentLighter {
    color: $colorAccentLighter !important;
}
.h-colorAccentLightest {
    color: $colorAccentLightest !important;
}
.h-colorAccentDark {
    color: $colorAccentDark !important;
}
.h-colorAccentDarker {
    color: $colorAccentDarker !important;
}
.h-colorAccentDarkest {
    color: $colorAccentDarkest !important;
}

.h-colorHighlight {
    color: $colorHighlight !important;
}
.h-colorDanger {
    color: $colorDanger !important;
}
.h-colorSuccess {
    color: $colorSuccess !important;
}
.h-colorWarning {
    color: $colorWarning !important;
}
.h-colorInfo {
    color: $colorInfo !important;
}

.h-colorGrey {
    color: $colorGrey !important;
}
.h-colorGreyLight {
    color: $colorGreyLight !important;
}
.h-colorGreyLighter {
    color: $colorGreyLighter !important;
}
.h-colorGreyLightest {
    color: $colorGreyLightest !important;
}
.h-colorGreyDark {
    color: $colorGreyDark !important;
}
.h-colorGreyDarker {
    color: $colorGreyDarker !important;
}
.h-colorGreyDarkest {
    color: $colorGreyDarkest !important;
}
.h-colorBlack {
    color: $colorBlack !important;
}
.h-colorWhite {
    color: $colorWhite !important;
}