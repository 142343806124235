/*  COLORS
========================================================================== */

/*
 * Apply color to text
 */

@import '../../../8-helpers/colors';

.h-colorLightBlue {
    color: $colorEPLightBlue !important;
}
.h-colorMediumBlue {
    color: $colorEPMediumBlue !important;
}
.h-colorDeepBlue {
    color: $colorEPDeepBlue !important;
}
.h-colorGrey {
    color: $colorEPGrey !important;
}
.h-colorGreen {
    color: $colorEPGreen !important;
}
.h-colorPurple {
    color: $colorEPPurple !important;
}
.h-colorOrange {
    color: $colorEPOrange !important;
}
.h-colorRed {
    color: $colorEPRed !important;
}
.h-colorYellow {
    color: $colorEPYellow !important;
}
.h-colorTeal {
    color: $colorSRGTeal !important;
}

.top-bar {
    background: none;
    border-top: 6px solid $colorBrandPrimary;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-top: 10px;
    &--light-blue {
        border-top: 6px solid $colorEPLightBlue;
    }
    &--medium-blue {
        border-top: 6px solid $colorEPMediumBlue;
    }
    &--red {
        border-top: 6px solid $colorEPRed;
    }
    &--purple {
        border-top: 6px solid $colorEPPurple;
    }
    &--green {
        border-top: 6px solid $colorEPGreen;
    }
    &--orange {
        border-top: 6px solid $colorEPOrange;
    }
}
.right-bar {
    background: none;
    border-right: 6px solid $colorBrandPrimary;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-right: 10px;
    &--light-blue {
        border-right: 6px solid $colorEPLightBlue;
    }
    &--medium-blue {
        border-right: 6px solid $colorEPMediumBlue;
    }
    &--red {
        border-right: 6px solid $colorEPRed;
    }
    &--purple {
        border-right: 6px solid $colorEPPurple;
    }
    &--green {
        border-right: 6px solid $colorEPGreen;
    }
    &--orange {
        border-right: 6px solid $colorEPOrange;
    }
}
.bottom-bar {
    background: none;
    border-bottom: 6px solid $colorBrandPrimary;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-bottom: 10px;
    &--light-blue {
        border-bottom: 6px solid $colorEPLightBlue;
    }
    &--medium-blue {
        border-bottom: 6px solid $colorEPMediumBlue;
    }
    &--red {
        border-bottom: 6px solid $colorEPRed;
    }
    &--purple {
        border-bottom: 6px solid $colorEPPurple;
    }
    &--green {
        border-bottom: 6px solid $colorEPGreen;
    }
    &--orange {
        border-bottom: 6px solid $colorEPOrange;
    }
}
.left-bar {
    background: none;
    border-left: 6px solid $colorBrandPrimary;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-left: 10px;
    margin-right: 5px;
    &--light-blue {
        border-left: 6px solid $colorEPLightBlue;
    }
    &--medium-blue {
        border-left: 6px solid $colorEPMediumBlue;
    }
    &--red {
        border-left: 6px solid $colorEPRed;
    }
    &--purple {
        border-left: 6px solid $colorEPPurple;
    }
    &--green {
        border-left: 6px solid $colorEPGreen;
    }
    &--orange {
        border-left: 6px solid $colorEPOrange;
    }
}