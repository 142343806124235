/* INFO LIST
========================================================================== */

.infoList {
    @include font-size(14px);

    padding-left: $spacingSm;

    list-style: none;

    color: $colorGrey;
    border-left: 3px solid $colorGreyLighter;

    @include mq(med) {
        padding: 0;

        border-width: 0;

        li {
            position: relative;

            display: inline-block;

            margin-right: $spacingSm;
            padding-right: $spacingSm;

            &:after {
                position: absolute;
                top: 0;
                right: -4px;

                content: '\2022';
            }
            &:last-child {
                margin-right: 0;
                padding-right: 0;

                &:after {
                    display: none;
                }
            }
        }
    }

    a {
        text-decoration: none;
    }
}
.blogItem-infoList {
    margin-bottom: $spacingXSm;
}
