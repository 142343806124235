/* MAIN HEADER
========================================================================== */

.navSignin {
    > ul {
        @extend %flexbox;

        margin: 0;
        padding: 0;

        list-style: none;

        > li {
            @include flex(1 1 auto);

            text-align: center;

            > a {
                display: block;

                padding: $spacingXSm $spacingSm;

                text-decoration: none;
                text-transform: uppercase;

                color: $colorWhite;

                &:hover {
                    text-decoration: none;
                }
            }
            &:hover {
                > a {
                    color: $colorWhite;
                    background: $colorBrandPrimaryDark;
                }
            }
        }
    }
}
.navSignin-signin {
    @extend .icon-profile;

    &:before {
        @extend %iconStyle;
    }
}
