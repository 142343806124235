/*
* Flexbox Direction
*
* The 'flex-direction' property specifies how flex items are placed in
* the flex container, by setting the direction of the flex container's
* main axis. This determines the direction that flex items are laid out in. 
*
* Values: row | row-reverse | column | column-reverse
* Default: row
*
* http:*w3.org/tr/css3-flexbox/#flex-direction-property
*/

@mixin contrast-generator($value, $value2: none, $direction: bottom) {
    color: color-contrast($value);
    @if $value2 == none {
        background-color: $value;
    } @else {
        background-image: linear-gradient(to $direction, $value, $value2);
    }
    .slick-dots {
        li {
            button {
                &:before {
                    @if $value2 == none {
                        background-color: $value;
                    } @else {
                        background-image: linear-gradient(to $direction, $value, $value2);
                    }
                    color: color-contrast($value);
                }
            }
            &.slick-active {
                button {
                    &:before {
                        @if $value2 == none {
                            background-color: $value;
                        } @else {
                            background-image: linear-gradient(to $direction, $value, $value2);
                        }
                        color: color-contrast($value);
                    }
                }
            }
        }
    }
    .featureBlock {
        .featureBlock-heading {
            color: color-contrast($value);
        }
    }
    &.job--listings {
        .job--listing {
            border: 1px solid color-contrast($value);
        }
    }
    &.navHorizontal.navSection {
        @if $value2 == none {
            background-color: $value;
        } @else {
            background-image: linear-gradient(to $direction, $value, $value2);
        }    
        a {
            color: color-contrast($value);
        }
    }
    &.reviewsRotator {
        .reviews-rating {
            .icon-star, .icon-star-half, .icon-star-alt, .icon-star-half-alt {
                color: color-contrast($value);
                &--white {
                    color: $colorWhite !important;
                }
            }
        }
    }
    .reviewsWrite {
        color: color-contrast($value);
    }
    .icon-cards {
        .aside {
            color: $colorText;
        }
    }
    .news-feed-container {
        .news-feed {
            li {
                h3 {
                    a {
                        color: color-contrast($value);
                    }
                }
            }
        }
    }
    .video-heading a, .spotlight-heading a {
        color: color-contrast($value);
    }
    .interior > p a {
        color: color-contrast($value);
        text-decoration: underline;
    }
}