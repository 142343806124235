/*  OVERLINE
========================================================================== */

/*
 * Add overlines.
 */

.h-overline {
    border-top: 1px solid $colorBorder !important;
}



