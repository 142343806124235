/* FEATURE BLOCK
========================================================================== */

.featureBlock {
    img {
        margin-bottom: $spacingBase;
        border-radius: $borderRadius;
    }
    &[class*='section--contrast'] {
        .featureBlock-figcaption {
            color: inherit;
        }
    }
}

.featureBlock-heading {
    text-align: center;
}
.featureBlock-figure {
    @extend %noPhatBottom;
    margin-bottom: $spacingLg;
}
.featureBlock-figcaption {
    padding-bottom: 0;

    border-bottom-width: 0;

    p {
        margin-bottom: $spacingSm;
        line-height:1.3;
    }
    .btn {
        margin-top:$spacingXSm;
        font-weight: bold;
    }
}
.featureBlock-subHeading {
    margin-bottom: $spacingSm;
    a {
        color:inherit;
        &:hover {
            text-decoration:underline;
        }
    }
}
// .featureBlock--four {
// }
.featureBlock-mainFeature {
    @extend %flexbox;

    @include flex-direction(column);
    text-align:left;

    @include mq(lg) {
        @include flex-direction(row);
        @include align-items(center);
    }
}
// .featureBlock-mainCopy {
//     overflow:hidden;
// }
.featureBlock-mainImage {
    @include mq(med) {
        padding-right: $spacingBase;
        flex-shrink:0;

        //float:left;
        //max-width:50%;
        //margin-right:$spacingBase;
    }
}
// .featureBlock--six .row {
//     clear:both;
// }
.featureBlock-mainFeature--imageRight {
    .featureBlock-mainImage {
        @include mq(med) {
            @include order(2);

            float: right;

            padding-right: 0;
            padding-left: $spacingBase;
        }
    }
}

@include mq(lg) {
    .featureBlock--four {
        .featureBlock-figure {
            margin-bottom: 0;
        }
    }
}

.featureBlock--tools {
    .contained {
        > .row {
            > .lg-8 {
                &:nth-child(n+4) {
                    .featureBlock-figure {
                        margin-top: $spacingLg;
                    }
                }
            }
            > .lg-12 {
                &:nth-child(n+3) {
                    .featureBlock-figure {
                        margin-top: $spacingLg;
                    }
                }
            }
            > .column {
                @include mq(sm-max) {
                    &:not(:first-of-type) {
                        margin-top: $spacingBase;
                    }
                }
            }
        }
    }
    .featureBlock-figure {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        .image-container {
            text-align: center;
            @include mq(sm-max) {
                img {
                    max-height: 150px;
                }
            }
        }
    }
}

.featureBlock--common {
    .row {
        align-items: center;
        .column {
            .featureBlock-figure {
                text-align: center;
            }
        }
    }
}