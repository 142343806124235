/* MAIN HEADER
========================================================================== */

.navCallout {
    > ul {
        @extend %flexbox;

        margin: 0;
        padding: 0;

        list-style: none;

        > li {
            @include flex(1 1 auto);

            text-align: center;

            > a {
                display: block;

                padding: $spacingXSm $spacingSm;

                text-decoration: none;
                text-transform: uppercase;

                color: $colorWhite;
                background:$colorBrandPrimary;

                &:hover {
                    text-decoration: none;
                }
            }
            &:hover {
                > a {
                    color: $colorWhite;
                    background: $colorBrandPrimaryDark;
                }
            }
        }
    }
}

/* Styles to address nav callout items that have children when viewed on mobile screens.
   This is necessary because navcallout does not end up in the drawer like the other 
   util navs. See skyline steel design for an example */
.headerMain-util {
   position: relative;
   .navCallout {
      > ul {
         > li.hasChildren {
            position: static;
            > ul {
               @include font-size(13px);
               transition: transform .2s ease-in-out;
               max-width: none;
               width: 100%;
               background: rgba(14,116,78,1);
               > li {
                  > a {
                     padding: $spacingXSm;
                  }
               }
            }

            &.is-open {
               background: $colorBrandPrimaryDark;
               ul {
                  transform: scale(1, 1);
               }
            }
            @include mq(lg) {
               position: relative;
               > ul {
                  @include font-size(11px);
                  > li {
                     > a {
                        padding: 0;
                     }
                  }
               }
            }
         }
      }
   }
}