/* LIST - CONTENT
========================================================================== */

.listContent {
}
.listContent-heading {
	text-align:center;
}
.listContentItem {
	@extend %noPhatBottom;
	@extend %clearfix;
	// border-bottom:1px solid $colorBorder;
	padding-bottom:$spacingBase;
	margin-bottom:$spacingBase;
    padding-inline: 26px;
}
.listContentItem-heading {
	margin-bottom:0;
}
.listContentItem-figure {
	text-align:left;
	margin-bottom:$spacingSm;
    img {
        border-radius: $borderRadius;
    }
}
 @include mq(med) {
 	.listContentItem-body {
 		overflow:hidden;
 	}
 	.listContentItem-figure {
 		float:right;
 		margin-left: $spacingBase;
 		margin-bottom:0;
 		max-width: 35%;
 		text-align:center;
 	}
    .listContentItem--right {
     	.listContentItem-figure {
            float:left;
            margin-left: 0;
            margin-right: $spacingBase;
        }
    }
}
