.video-popup-container {
    position: relative;
    a {
        position: relative;
    }
    .play-block {
        position: absolute;
        display: block;
        opacity: .7;
        background-color: $colorWhite;
        color: $colorBlack;
        padding: $spacingXSm $spacingBase;
        border-radius: 5px;
        font-size: 35px;
        margin-left: -40px;
        left: 50%;
        margin-top: -25px;
        top: 0;
        &:after {
            content: '';
            display: inline-block;
            position: relative;
            top: 8px;
            left: 3px;
            border-style: solid;
            border-width: 22.5px 0 22.5px 39.0px;
            border-color: transparent transparent transparent $colorBrandPrimary;
        }
    }
    h1, h2, h3, h4, h5, h6 {
        margin-block: $spacingSm;
        flex: 1;
    }
    img {
        border-radius: $borderRadius;
        width: 100%;
		aspect-ratio: 16 / 9;
		object-fit: cover;
		height: auto;
    }
}
.mfp-fade.mfp-bg {
	opacity: 0;
	-webkit-transition: all 0.15s ease-out; 
	-moz-transition: all 0.15s ease-out; 
	transition: all 0.15s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready {
	opacity: 0.8;
}
.mfp-fade.mfp-bg.mfp-removing {
	opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
	opacity: 0;
	-webkit-transition: all 0.15s ease-out; 
	-moz-transition: all 0.15s ease-out; 
	transition: all 0.15s ease-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
	opacity: 1;
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
	opacity: 0;
}
.mfp-iframe-scaler {
    outline: 2px solid $colorWhite;
}