/* QUOTES
========================================================================== */

/*
 * Inline quotes.
 *
    <p>Sed ut perspiciatis unde <q>omnis iste natus error</q> sit voluptatem</p>
 *
 */
q {
    q:before {
        content: open-quote;
    }
    q:after {
        content: close-quote;
    }
}


/*
 *
   <blockquote>
       <p>Insanity: doing the same thing over and over again and expecting
       different results.</p>
       <cite>Albert Einstein</cite>
   </blockquote>
 *
 */
blockquote {
    float: none;

    margin: 0 0 $lineHeight 0;
    padding: $spacingSm $spacingSm $spacingSm $spacingBase;

    border-left: 3px solid $colorGreyLighter;
    background: $colorGreyLightest;

    /* unncomment if you want quotes 
    p:before{
        content:"\201C";
    }
    p:last-of-type:after{
        content:"\201D";
    }
    */
    p:last-of-type {
        margin-bottom: 0;
    }
}

cite {
    display: block;

    text-indent: 0;

    &:before {
        content: '\2014';
    }
}
