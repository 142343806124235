/* LISTS
========================================================================== */ 

/*
* Have a numbered `ul` without the semantics implied by using an `ol`. */
.list--numbered, ol {
	list-style-type: decimal;
}
.list--horizontal {
	margin-left:0;
	padding: 0;
	list-style-type: none;
	li {
		display: inline-block;
		//bottom margin is in case of wrapping
		margin: 0 $spacingSm $spacingXSm 0;
	}
	&.list--separated {
		li {
			padding-left: $spacingSm;
			position: relative;
			&:before {
				content: '·';
				position: absolute;
				top: 0;
				left: -4px;
			}
			&:first-child {
				padding-left:0;
				&:before {
					display:none;
				}
			}
		}
	}
	@include mq(med) {
		li {
			margin-bottom:0;
		}
	}
}
.list--bare {
	list-style: none;
	padding: 0;
	margin-left:0;
}
.list--block, .list--block--arrows, .list--block--checks {
	list-style: none;
	padding: 0;
	margin-left:0;
	border-top:1px solid $colorBorder;
	li {
		line-height:1.2;
		padding:$spacingXSm;
		border-bottom:1px solid $colorBorder;
	}
}
.list--block--arrows, .list--block--checks {
	border:none;
	padding:0 0 0 $spacingBase !important;
	li {
		padding:$spacingXSm 0;
		border:none;
		&:before {
			@extend %iconStyle;
			@extend .icon-caret-right;
			margin-left:-$spacingBase*.9;
		}
	}
}
.list--block--checks {
	li {
		&:before {
			@extend %iconStyle;
			@extend .icon-check;
		}
	}
}
.list--columns, .listColumns {	
	padding:0 0 0 $spacingSm*1.25;
	@include mq(sm) {
        -moz-column-count: 2;
	    -moz-column-gap: $spacingLg;
	    -webkit-column-count: 2;
	    -webkit-column-gap: $spacingLg;
	    column-count: 2;
	    column-gap: $spacingLg;		
	}        
    @include mq(med) {  	
	    &.three {
		    -moz-column-count: 3;
		    -webkit-column-count: 3;
		    column-count: 3;
	    }      
	    &.four {
		    -moz-column-count: 4;
		    -webkit-column-count: 4;
		    column-count: 4;
	    }
    }   
}

.list--offerings {
	margin-top:$spacingXSm;
	font-size:11px;
    display: none;
	li {
		background:$colorGreyLightest;
		padding:2px 4px;
		margin:0 0 5px 5px;
	}
}