.gradient {
    &--green_blue-top {
        background-image: linear-gradient($colorEPGreen, $colorEPLightBlue);
    }
    &--green_blue-bottom {
        background-image: linear-gradient(to top, $colorEPGreen, $colorEPLightBlue);
    }
    &--green_blue-left {
        background-image: linear-gradient(to right, $colorEPGreen, $colorEPLightBlue);
    }
    &--green_blue-right {
        background-image: linear-gradient(to left, $colorEPGreen, $colorEPLightBlue);
    }

    &--blue_purple-top {
        background-image: linear-gradient($colorEPLightBlue, $colorEPPurple);
    }
    &--blue_purple-bottom {
        background-image: linear-gradient(to top, $colorEPLightBlue, $colorEPPurple);
    }
    &--blue_purple-left {
        background-image: linear-gradient(to right, $colorEPLightBlue, $colorEPPurple);
    }
    &--blue_purple-right {
        background-image: linear-gradient(to left, $colorEPLightBlue, $colorEPPurple);
    }

    &--orange_purple-top {
        background-image: linear-gradient($colorEPOrange, $colorEPPurple);
    }
    &--orange_purple-bottom {
        background-image: linear-gradient(to top, $colorEPOrange, $colorEPPurple);
    }
    &--orange_purple-left {
        background-image: linear-gradient(to right, $colorEPOrange, $colorEPPurple);
    }
    &--orange_purple-right {
        background-image: linear-gradient(to left, $colorEPOrange, $colorEPPurple);
    }

    &--red_yellow-top {
        background-image: linear-gradient($colorEPRed, $colorEPYellow);
    }
    &--red_yellow-bottom {
        background-image: linear-gradient(to top, $colorEPRed, $colorEPYellow);
    }
    &--red_yellow-left {
        background-image: linear-gradient(to right, $colorEPRed, $colorEPYellow);
    }
    &--red_yellow-right {
        background-image: linear-gradient(to left, $colorEPRed, $colorEPYellow);
    }

}