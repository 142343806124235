/* LIST - Files
========================================================================== */
$colorPDF: $colorDanger; // #da1f1f
$colorExcel: #207245;
$colorWord: #2a5699;
$colorPowerpoint: #d04424;
$colorWeb: #0000FF;

// specific edits for Results Bar on List Files module
// This will be used in a future iteration of the Document Library
// .listFiles {
// 	.resultsTools {
// 		background:$colorGreyLightest;
// 		padding:$spacingSm;
// 		.resultsTools-options {
// 			align-items:flex-start;
// 			.filters { width:100% }
// 			.filters-list {
// 				margin:0;
// 				li {
// 					display:inline-block;
// 					margin:0;
// 				}
// 			}			
// 		}

// 	}
// }

.listFiles-listing {
	@extend .list--bare;
	@include font-size(17px);
	.icon-file-pdf:before {color:$colorPDF;}
	.icon-file-word:before {color:$colorWord;}
	.icon-file-excel:before {color:$colorExcel;}
	.icon-file-powerpoint:before {color:$colorPowerpoint;}
	.icon-file-code:before,.icon-file-image:before,.icon-file-archive:before, 
	.icon-file-audio:before, .icon-file-video:before, .icon-file:before {color:$colorText;}
	padding:0 $spacingSm;
	> li {
		padding:$spacingXSm*1.1 0;
		border-bottom:1px solid $colorBorder;
		&:before {
			@include font-size(22px);
		}
	}
	p {
		@include font-size(14px);
		margin:0;
		@include mq(med) {
			margin-left:$spacingBase*1.3;
		}
	}
}
.listFiles-infoList {
	@include font-size(12px);
	line-height:1.2;
	padding:$spacingXSm;
	border-bottom:none;
	background:$colorGreyLightest;
	color:$colorText;
	border-radius:5px;
	display:inline-block;
	vertical-align:1px;
}