/* SEARCH BAR
========================================================================== */ 

/* Searchbox/button */
.searchBox {
	text-align:right;
	position:relative;
	z-index: 3;
}
.searchBox-heading {
	span {
		font-weight:normal;
		font-style:italic;
		font-size:80%;
	}
}
.searchBox-textField {
	padding-right: 43px;
	margin:0;
	height:38px;
	line-height:38px;
	border-color:$colorGreyLight;
	color:darken($colorGrey, 45%);
}
.searchBox-suggestions {
	display: none;
	@include mq(med) {
		& {
			display: block;
			position: absolute;
			z-index: 1;
			margin-top: (-1 * $spacingBase) - 1;
			width: 100%;
			border: solid 1px $colorHighlight;
			padding: $spacingSm;
			text-align: left;
			background: $colorWhite;

			&-heading {
				font-family: $fontHighlight;	
    			@include font-size($h5Size);
			}

			&-item {
				$item: &;

				text-align: center;
				cursor: pointer;

				&-image {
					margin-bottom: $spacingSm;
				}

				&-name {
					margin-bottom: 0;
					@include font-size(18px);
					color: $colorLinks;
				}

				&:hover {
					#{ $item }-name {
						color: $colorLinksHover;
					}
				}
			}
		}
	}
}
.searchBox--hiVis {
	margin-bottom:$spacingLg*.75;
	.searchBox-textField {
		border-color:$colorHighlight;
	}
	.searchBox-submit {
		background: $colorHighlight;
	}
}
.searchBox--main {
	@include font-size(20px);
    position: relative;
    .searchBox-textField {
		border-top-width:0;
		border-right-width:0;
		border-left-width:0;
		border-color:$colorWhite !important;
        display: inline-block;
        height: 41px;
        padding-right: ($spacingLg + $spacingBase);
	}
    input[type="text"] {
        @include mq(med) {
            min-width: 300px;
        }
    }
}

.search-container {
    position: relative;
    input {
        display: inline-block;
        @include font-size(16px);
        height: 41px;
        margin-bottom: 0;
        padding-right: ($spacingLg + $spacingBase);
        padding-left: $spacingSm;
        border: 1px solid $colorGreyLight;
        border-radius: $borderRadius;
    }
    a {
        padding-inline: $spacingBase;
        position: absolute;
        right: 0;
    }
}