/* PAGE META
========================================================================== */

.pageMeta {
	@include mq(med) {
		border-bottom:1px solid $colorGreyLighter;
	}
}	
.pageMeta-inner {
	@extend %clearfix;
	@include mq(med) {
		padding-right:$gutterBase;
		padding-left:$gutterBase;
	}
	@include mq(xlg) {
		padding-right:$spacingLg;
		padding-left:$spacingLg;
	}
}

//***********************



