/* MAIN HEADER
========================================================================== */

.headerMain {
    @include flex(0 0 auto);

    position: relative;
    z-index: 3;
}

.headerMain-util {
    @include font-size(14px);

    padding-right: $gutterBase;
    padding-left: $gutterBase;

    background: $colorBrandPrimary;

    @include mq(xlg) {
        padding-right: $spacingLg;
        padding-left: $spacingLg
    }
}

.headerMain-utilInner {
    @include mq(lg) {
        @include flexbox;
        @include justify-content(space-between);
        padding-block: $spacingXSm;
    }
}

.headerMain-utilZone01 {
    @include flexbox;
    @include align-items(center);

    //border: 1px solid green;
    > * {
        @include flex(1 1 auto);
    }
}

.headerMain-utilZone02 {
    @include flexbox;
    @include align-items(center);

    //border: 1px solid blue;
    > * {
        @include flex(1 1 auto);
    }
}

.headerMain-utilZone03 {
    @include flexbox;
    @include align-items(center);

    //border: 1px solid red;
    > * {
        @include flex(1 1 auto);
    }
    display: none;
}

.headerMain-main {
    @include transition(all .2s ease-in-out);

    padding-right: $gutterBase;
    padding-left: $gutterBase;

    border-bottom: 1px solid $colorGreyLighter;

    @include mq(xlg) {
        padding-right: $spacingLg;
        padding-left: $spacingLg;
    }

    // style the classes which make headerMain-main sticky on scroll
    [class*='js-headerFixed'] & {
        position: fixed;
        right: 0;
        left: 0;
        top: 0;

        background: rgba($colorWhite, .95);
        @include mq(sm-max) {
            .franchiseAddress, .icon-location, .icon-mail {
                display: none;
            }
        }
    }
    .js-headerFixed--up & {
        top: -62px;
        @include mq(med) {
            top: 0;
        }
    }
    .js-headerFixed--down & {
        top: 0;
    }
    // padding-bottom: $spacingBase;
    // @include mq(med) {
        padding-bottom: 0;
    // }
    @include mq(sm-max) {
        .icon-location, .icon-mail {
            display: none;
        }
    }

}

.headerMain-mainInner {
    @extend %flexbox;

    @include justify-content(space-between);
    flex-direction: column;
    @include mq(med) {
        flex-direction: row;
    }
}

.headerMain-mainZone01 {
    @include flexbox;
    @include align-items(center);

    //border: 1px solid green;
    > * {
        @include flex(1 1 auto);
    }
}

.headerMain-mainZone02 {
    @include flexbox;
    @include align-items(center);

    // @include mq(lg) {
        //shift to the right on lg screens
        // margin-left: auto;
    // }

    //border: 1px solid blue;
    > * {
        @include flex(1 1 auto);
    }
}

.headerMain-mainZone03 {
    @include flexbox;
    @include align-items(center);

    //border: 1px solid red;
    > * {
        @include flex(1 1 auto);
    }
}

.headerMain-metro {
    .headerMain-mainZone01, .headerMain-mainZone02, .headerMain-mainZone03 {
        flex: 1;
    }
    .headerMain-mainZone03 {
        flex-direction: column;
        .mobileDrawerToggle {
            border-left: none;
        }
    }
    .logoMain {
        text-align: left;
        @include mq(med) {
            text-align: center;
        }
    }
    .locationRotator-container {
        max-width: 400px;
        .slick-track {
            display: flex;
        }
        .slick-prev, .slick-next {
            width: 30px;
            height: 30px;
            &:before {
                font-size: 32px;
                color: $colorBrandPrimary;
            }
        }
        .slick-prev {
            &:before {
                content: '\e814';
            }
        }
        .slick-next {
            &:before {
                content: '\e815';
            }
        }
        .locBlurb {
            margin: $spacingBase;
            border: 1px solid $colorBrandPrimary;
            border-radius: $borderRadius;
            max-width: 350px;
            padding-left: $spacingSm;
            height: inherit !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            h1, > p, > div {
                margin-bottom: 0;
            }
            .contact-phone {
                display: flex;
                justify-content: space-between;
                .telephone {
                    &:before {
                        @extend %iconStyle;
                        @extend .icon-phone;
                    }
                }
            }
        }
    }
}