/* LOCATION RESULTS
========================================================================== */

.locationResults-heading {
    text-transform:initial;
    font-family:$fontHighlight;
}
.locationResults-list {
    @extend %flexbox;

    @include font-size(14px);
    @include flex-wrap(wrap);
    @include justify-content(flex-start);

    padding: 0;

    list-style: none;

    counter-reset: numbers;

    > li {
        position: relative;
        margin-bottom:$spacingBase;
        display: flex;
        flex-direction: column;
        width: 100%;
        @include mq(med) {
            padding: $spacingSm $spacingBase $spacingSm 0;
            &:nth-child(even) {
                padding: $spacingSm 0 $spacingSm $spacingBase;
            }
        }
        .locationResults-item-container {
            padding: $spacingBase $spacingLg;
            border:1px solid $colorBrandPrimary;
            border-radius: $borderRadius;
            position: relative;
            &:before {
                // font-family: $fontIcon;
                // content: '\e8df';
                // position: absolute;
                // left: 20px;
                // top: 20px;
                // font-size: 22px;
                position: absolute;
                top: 25px;
                left: 20px;
    
                width: $spacingBase;
                height: $spacingBase;
                border-radius:50%;
    
                content: '' counter(numbers, decimal) '';
                counter-increment: numbers;
                text-align: center;
    
                color: $colorBrandPrimary;
                background: $colorWhite;
                border:1px solid $colorBrandPrimary;
                font-weight:bold;
                line-height:1.75;
            }
        }
        .btn {
            @include font-size(14px);
            margin:$spacingSm $spacingBase 0 0;
            padding:$spacingXSm*.5 $spacingSm;
        }
        p {margin:0}
        .locationResults-info {
            flex: 1;
            margin-top: $spacingSm;
            .locationResults-email, .locationResults-phone {
                display: block;
                min-height: 48px;
                @include mq(med) {
                    min-height: auto;
                }
            }
        }
        // &:before {
        //     position: absolute;
        //     top: $spacingSm;
        //     left: 8px;

        //     width: $spacingBase;
        //     height: $spacingBase;
        //     border-radius:50%;

        //     content: '' counter(numbers, decimal) '';
        //     counter-increment: numbers;
        //     text-align: center;

        //     color: $colorBrandPrimary;
        //     background: $colorWhite;
        //     border:1px solid $colorBrandPrimary;
        //     font-weight:bold;
        //     line-height:1.75;
        // }        
        @include mq(med) {
            @include flex(1 1 50%);
            max-width:50%;
            // padding-bottom:$spacingXLg*.8;             
            // p:last-of-type {
            //     padding:0;
            //     position:absolute;
            //     bottom:0;
            // }                       
        }
    }
}
.section.locationResults {
    .locationResults-list {
        .locationResults-item:last-child {
            margin-bottom: $spacingBase !important;
        }
    }
}
.locationResults-name {
    @include font-size(18px);
    line-height:1.3;
    margin-bottom:0;
    text-transform:initial;
    flex: 1;
}
.locationResults-miles {
    @include font-size(14px);
    font-weight:bold;
    margin:0 0 $spacingSm*1.5 0;
    display: none;
}
.locationResults-phone,
.locationResults-fax {
    &:before {
        @extend %iconStyle;
    }
}
.locationResults-phone {
    @extend .icon-mobile;
}
.locationResults-fax {
    @extend .icon-printer;
}
.locationResults {
    &.stateListing {
       .locationResults-list > li {
           &:before {
            display:none;
           } 
       }
    }
}
.locationResults--hours {
    .toggleLink {
        &::after {
            content: '\e813';
            display:inline-block;
            font-family:$fontIcon;
            margin-left:$spacingXSm;
        }
        &.is-active::after {
            content: '\e812';
        }
    }
    .toggleLinkTarget {
        border-bottom:1px solid $colorGreyLighter;
        padding-bottom:$spacingXSm;
        margin-bottom:$spacingSm;
    }
}
.locationResults--time {
    span:first-of-type {margin-left:$spacingBase*.75}
    font-size:13px;
    display:flex;
    justify-content:space-between;
}
// Preview window over integrated map in Dev
.gm-style-iw.gm-style-iw-c {
    max-width:250px
}
.infowindow {
    // Location heading
    h2 {font-size:$h6Size;}
    .btn, .btn:hover {
        background:transparent;
        border-color:transparent;
        padding:0;
        color:$colorBrandPrimary;
        text-decoration:underline;
        width:100%;
        text-align:left;

    }
}