.news-feed-container {
    max-height: 450px;
    overflow-y: scroll;
    .news-feed {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            &:not(:first-of-type) {
                border-top: solid 1px #e7e8e9;
            }
            padding: 0 8px;
            h3 {
                @include font-size(16px);
                margin: 12px 0 2px 0;
                padding: 0;
            }
            p {
                @include font-size(14px);
            }
        }
    }
}