/* MAIN MOBILE NAVIGATION
========================================================================== */

.navMain-mobile a {
    display: block;

    font-weight: 500;
    &.btn {
        margin: $spacingSm;
    }
    &:hover {
        text-decoration: none;
    }
}
.navMain-mobile ul {
    padding: 0;

    list-style: none;
}
.navMain-mobile > ul,
.drawer-NavUser > ul {
    > li {
        clear: both;

        border-bottom: 1px solid $colorGreyLighter;

        > a {
            display: block;

            padding: $spacingXSm $spacingSm;

            cursor: pointer;
        }
        > ul {
            @include transition(all .2s ease-in-out);

            overflow: hidden;

            max-height: 0;
            margin: 0 $spacingSm;
            margin-bottom: 0;

            -webkit-transform: scale(1, 0);
                -ms-transform: scale(1, 0);
                    transform: scale(1, 0);
            -webkit-transform-origin: top; /* Chrome, Safari, Opera */
                -ms-transform-origin: top; /* IE 9 */
                    transform-origin: top;

            opacity: 0;

            > li {
                margin-bottom: $spacingSm;
                padding-left: $spacingSm;

                a {
                    @include font-size(16px);

                    font-weight: 400;
                }
                > a {
                    text-transform: uppercase;
                }
            }
        }
    }
}
.navMain-mobile-subToggle {
    @extend .icon-caret-down;

    position: relative;
    z-index: 2;

    display: inline-block;
    float: right;

    padding: $spacingXSm $spacingSm;

    color: $colorGrey;
    background: $colorGreyLightest;

    &:before {
        @extend %iconStyle;

        margin: 0;
    }
}
.navMain-mobile > ul li.is-active {
    ul {
        max-height: none;

        -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
                transform: scale(1, 1);
        -webkit-transform-origin: top center;
            -ms-transform-origin: top center;
                transform-origin: top center;

        opacity: 1;
    }
    .navMain-mobile-subToggle {
        @extend .icon-caret-up;

        background: rgba($colorWhite,.75);
    }
}
body.is-toggled--drawer {
    overflow: hidden;

    height: 100%;

    -webkit-overflow-scrolling: none;

    .drawer {
        -webkit-transform: translate(0, 0);
            -ms-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    .pageMask {
        visibility: visible;

        opacity: 1;
    }
}
