/* REVIEWS
========================================================================== */
.reviewsRotator {
	padding:$spacingBase;
	.contained {
		text-align:center;
		max-width:800px;
		margin:auto;

		
	}
	@include mq(med) {
		padding:$spacingLg 0;
	}
}
.reviewsRotator-content {
	display:none;
	@extend .contentSlider-content;
	padding:0;
	p {margin:0 0 $spacingXSm 0;}
}
.reviewsRotator-link {
	margin-top:$spacingBase;
}

// Needs differentiator in order to override slick slide
.slick-slide.reviewsRotator-item {
	@extend .slick-slide.contentSlider-item;
}
// These styles affect Reviews Listing & Reviews Rotator
.reviews-heading {
	@extend .featureBlock-heading;
	@include font-size(26px);
	line-height:1.4;
	margin:0 auto $spacingXSm auto;
}
p.reviews-rating {
	//color:$colorAGYellow;
	margin: 0 0 $spacingBase 0;
	a {
		@include font-size(14px);
		margin:0 0 0 $spacingXSm;
	}
	span {color:$colorBrandPrimary;}
}

.reviews-content {
	p:first-of-type {
		@include font-size(22px);
		line-height:1.5;
	}
}
.reviews-byline {
	@extend .contentSlider-byline;
	@include font-size(14px);
	margin-top:$spacingSm;
    font-weight: bold;
}

// Specific changes for listing
.reviewsListing {
	padding: $spacingBase 0 $spacingLg;
	p.reviews-rating {margin:0;}
	ul {
		@extend .list--bare;
		padding-top: 10px;
		li {
			@include mq(med) {
				.reviewsListing-review {
					margin:0 0 $spacingLg 0;
					padding:0 $spacingBase*.75;
					border-left:5px solid $colorBrandPrimary;
				}
			}
		}
	}		
}
.reviewsRed {
    background-color: $colorBrandPrimary;
    color: $colorWhite;
}
.btn.btn--tertiary {
    color: $colorBrandPrimary;
    background-color: $colorWhite;
    &:hover {
        color: $colorWhite;
        background-color: $colorBlack;
    }
}
.reviews-date {
    display: none;
}
.reviewsSlider {
    padding-bottom: 0;
    .contentSlider-content {
        padding-bottom: $spacingSm;
    }
}
.reviewsWrite {
    font-weight: bold;
    color: $colorBrandPrimary;
}
.reviews-rating {
    .icon-star, .icon-star-half, .icon-star-alt, .icon-star-half-alt {
        color:$colorBrandPrimary;
        &--white {
            color:$colorWhite !important;
        }
    }
}