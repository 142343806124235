/* <HTML>
========================================================================== */

html{
    overflow-x:hidden;

	width: 100%;
	min-height: 100%;
	margin: 0;
	padding: 0;

	font:#{($fontSizeBase/16px)*1em}/#{$lineHeightRatio} $fontDefault;
    font-weight:300;
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;

	color: $colorText;
	background-color: #fff;

	-moz-osx-font-smoothing: antialiased;
    -webkit-size--adjust: 100%; 
    -ms-size--adjust: 100%;
    -webkit-overflow-scrolling: touch;
}
