/* CONTENT ROTATOR
========================================================================== */

.contentSlider-content {
	padding-bottom:$spacingLg;
	margin-bottom:0;
}
.contentSlider-item {
	@include responsive-font(5vw, 16px, 20px, 18px);
	text-align:center;
}
// Needs differentiator in order to override slick slide
.slick-slide.contentSlider-item {
	@include responsive-font(5vw, 16px, 20px, 18px);
	text-align:center;
	display:flex;
	height:auto;
	flex-direction:column;
	justify-content:center;
}
.contentSlider-byline {
	@include font-size(14px);
	opacity:.75;
	text-transform:uppercase;
	&:before {
		content:'-' '\00a0';
	}
}