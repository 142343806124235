/* MAIN HEADER
========================================================================== */

.navUser {
    text-transform: uppercase;

    color: $colorWhite;

    //style all Ul
    ul {
        margin: 0;
        padding: 0;

        list-style: none;
    }
    //style top level Ul and Li
    > ul {
        > li {
            position: relative;

            //style nested (hidden) ul
            > ul {
                @include transition(transform .2s ease-in-out, max-height .2s ease-in-out)
                max-height: 0;

                -webkit-transform: scale(1,0);
                    -ms-transform: scale(1,0);
                        transform: scale(1,0);
                -webkit-transform-origin: top;
                    -ms-transform-origin: top;
                        transform-origin: top;

                background: rgba($colorBrandPrimaryDark,.95);
            }
        }
    }
    //style all links
    a {
        display: block;

        color: $colorWhite;
    }
    //style nested links
    > ul > li > ul a {
        @include transition(all .25s ease-in-out);

        padding: 0 $spacingXSm;

        &:hover {
            background: rgba($colorWhite,.15);
        }
    }
}
//style the mobile toggled state
.navUser.is-active {
    //show the nested ul
    > ul > li > ul {
        max-height: 500px;
        margin-bottom: $spacingSm;
        padding: $spacingXSm;

        -webkit-transform: scale(1,1);
            -ms-transform: scale(1,1);
                transform: scale(1,1);
    }
}
//style the hover to show nested nav state for desktop
@include mq(lg) {
    .navUser > ul > li {
        position: relative;

        > ul {
            position: absolute;
            z-index: 10;
            right: 0;

            min-width: 200px;
        }
        &:before {
            @include transition(all .2s ease-in-out);

            position: absolute;
            right: 0;
            bottom: -4px;
            left: 0;

            height: 4px;

            content: '';

            opacity: 0;
            background-color: $colorBrandPrimaryDark;
        }
        &:hover {
            > ul {
                max-height: 500px;
                padding: $spacingXSm;

                -webkit-transform: scale(1,1);
                    -ms-transform: scale(1,1);
                        transform: scale(1,1);
            }
            &:before {
                bottom: 0;

                opacity: 1;
            }
        }
    }
}
.navUser-message {
    @extend %flexbox;

    padding-top: $spacingXSm;
    padding-bottom: $spacingXSm;

    cursor: default;
    font-weight: bold;

    border-top: 1px solid rgba($colorWhite,.5);

    @include mq(lg) {
        display: block;

        border-top-width: 0;
    }
}
.navUser-icon {
    @extend .icon-profile;

    margin-left: auto;
    padding-left: $spacingXSm;

    &:before {
        @extend %iconStyle;
    }
    &:after {
        display: inline-block;

        width: 0;
        height: 0;
        margin-left: 5px;

        content: '';
        vertical-align: middle;

        border-top: 4px solid;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
    }

    @include mq(lg) {
        margin-left: $spacingXSm;

        border-left: 1px solid rgba($colorWhite,.5);
    }
}
