/* PAGINATION
========================================================================== */

.pagination {
    margin-bottom:0;
    list-style:none;
    font-size:0;
    margin:auto;
    .pageNumbers {
        @extend %flexbox;
        @include justify-content(center);
        a,
        span {
          @include font-size(13px);
            padding: 0 $spacingXSm;
            text-align: center;
        }
  
        .current {
            color: $colorGrey;
        }
        .prev, .next {
          background: $colorBrandPrimary;
          border-radius: $borderRadius;
          color: color-contrast($colorBrandPrimary);
          padding:0.2em $spacingSm;
          line-height:18px;
          font-size:14px;
          text-transform:uppercase;
          &:before, &:after {
            font-family: Fontello;
            font-size:14px;
            display:inline-block;
            // height:1em;
            // width:1em;
          }   
        }
        .prev {
          // border-radius:2px 0 0 2px;
          &:before {
            content: '\e814';
            margin-right:5px;
          }
          &:hover {
            color:$colorWhite;
          }     
        }
  
        .next {
          // border-radius:0 2px 2px 0;
          &:after {
            content: '\e815';
            margin-left:5px;
            color:$colorWhite;
            font-family: Fontello;
            font-size:14px;
            display:inline-block;        
          }
          &:hover {
            color:$colorWhite;
            background:$colorLinksHover;
          }
        } 
        .inactive {
          pointer-events: none;
  
          background: $colorGreyLight;
        }    
    }
}  