/*BLOG ITEM
=======================================================================================*/

.searchItem {
	@extend %noPhatBottom;
	@extend %clearfix;
	border-bottom:1px solid $colorGreyLight;
	padding-bottom:$spacingBase;
	margin-bottom:$spacingBase;
}
.searchItem-heading {
	@include font-size(32px);
	margin-bottom:0;
	a {
		text-decoration:none;
	}
}
.searchItem-figure {
	text-align:left;
	margin-bottom:$spacingSm;
}
 @include mq(med) {
 	.searchItem-body {
 		overflow:hidden;
 	}
 	.searchItem-figure {
 		float:right;
 		margin-left: $spacingBase;
 		margin-bottom:0;
 		max-width: 35%;
 		text-align:center;
 	}
 }