/* MEDIA QUERIES
========================================================================== */
/*
* We are setting the desktopOnly variable to false as a default here.
* In editor.scss we set the variable to true
* See below for why
*/
$desktopOnly:false !default;

@mixin mq($mq){

    @if $desktopOnly == true {

      /* 
      * This is used for the editor stylesheet. It basically just compiles all the styles with no
      * media queries so we end up with the desktop rendering at the end.
      * The iAPPS editor uses an iframe so if that iframe is only as wide as a mobile device we don't want
      * the mobile classes showing
      */

       @content;

    }

    @else if $mq == xsm-only{

      /* 
      * xsm-only will only apply in between the beginning and end of xsm.
      */

        @media only screen and (min-width:$startXSm) and (max-width:$endXSm) { @content; }

    }

    @else if $mq == xsm{

      /*
      * xsm will apply styles at beginning of xsm and up
      */

        @media only screen and (min-width:$startXSm) { @content; }

    }

    @else if $mq == sm-max{

        /* 
        * sm-only will only apply in between the beginning and end of sm.
        */
  
          @media only screen and (max-width:$endSm) { @content; }
  
      }
  
      @else if $mq == sm-only{

      /* 
      * sm-only will only apply in between the beginning and end of sm.
      */

        @media only screen and (min-width:$startSm) and (max-width:$endSm) { @content; }

    }

    @else if $mq == sm{

      /*
      * sm will apply styles at beginning of sm and up
      */

        @media only screen and (min-width:$startSm) { @content; }

    }

    @else if $mq == med-only{

      /*
      * med-only will only apply in between the beginning and end of med.
      */

        @media only screen and (min-width:$startMed) and (max-width:$endMed) { @content; }

    }

    @else if $mq == med{

      /*
      * med will apply styles at beginning of med and up
      */

        @media only screen and (min-width:$endSm) { @content; }

    }

    @else if $mq == lg-only{

      /*
      * lg-only will only apply in between the beginning and end of lg.
      */

        @media only screen and (min-width:$startLg) and (max-width:$endLg) { @content; }

    }

    @else if $mq == lg{

      /*
      * lg will apply styles at beginning of large and up
      */

        @media only screen and (min-width:$startLg) { @content; }

    }

    @else if $mq == xlg-only{

      /*
      * xlarge-only will only apply in between the beginning and end of xlarge.
      */

        @media only screen and (min-width:$startXLg) and (max-width:$endXLg) { @content; }

    }

    @else if $mq == xlg{

      /*
      * xlg will apply styles at beginning of xlg and up
      */

        @media only screen and (min-width: $startXLg) { @content; }

    }
    @else if $mq == xxlg{

      /*
      * xxlg will apply styles at beginning of xxlg and up
      *
      * No need for an xxlg-only as this is the largest size so is no endpoint
      */

        @media only screen and (min-width: $startXXLg) { @content; }

    }

      /*
      * Just to remain consistent in how we write our media queries
      * numeric values should be added in the same format
      *
      
        @include mq(525px) {
          [styles]
        }

      */

    @else {
      @media only screen and (min-width: $mq) { @content; }
    }

}
