/* COLORS
========================================================================== */

/*default buttons*/
$btnBg: $colorBrandPrimary !default;
$btnBorderColor: $colorBrandPrimary !default; //darken($btnBg, 5%) !default;
$btnHoverBg: lighten($colorBrandPrimary, 20%) !default;
$btnHoverBorderColor: lighten($colorBrandPrimary, 20%) !default; //darken($btnHoverBg, 5%) !default;

/*secondary buttons*/
$btnSecondaryBg: $colorBrandSecondary !default;
$btnSecondaryBorderColor: $colorBrandSecondary !default; //darken($btnSecondaryBg, 5%) !default;
$btnSecondaryHoverBg: rgba(11, 44, 92, 0.79) !default; //darken($btnSecondaryBg, 5%) !default;
$btnSecondaryHoverBorderColor: rgba(11, 44, 92, 0.79) !default; //darken($btnSecondaryHoverBg, 5%) !default;