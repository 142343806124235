.employer-tools {
    list-style: none;
    padding-left: 0;
    li {
        &:not(:first-child) {
            margin-top: $spacingBase;
        }
        .employer-tool {
            display: flex;
            justify-content: center;
            .employer-image {
                margin-right: $spacingBase;
            }
            .employer-information {
                flex: 1;
            }
        }
    }
}