/* MAIN HEADER
========================================================================== */

.mobileDrawerToggle {
    @extend %flexbox;
    @extend .icon-menu;

    @include align-items(center);
    @include justify-content(center);
    @include flex(1 1 0);

    padding: 0 $spacingXSm;

    border-left: 1px solid $colorGreyLighter;

    @include mq(lg) {
        display: none;
    }

    &:before {
        @extend %iconStyle;

        @include font-size(40px);

        margin: 0;
    }
}
