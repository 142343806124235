/* BODY
========================================================================== */

body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: visible !important;

    > form {
        height: 100%;
    }
}
