/* SECTION
========================================================================== */

%section {
    @extend %guttered;
    @extend %noPhatBottom;
    clear:both;

    padding-top: $spacingLg;
    padding-bottom: $spacingLg;

    // Parallax is adding a scroll bar to sections
    overflow:hidden;

    &--borderEnds {
        position: relative;
        border-top:1px solid $colorBorder;
        border-bottom:1px solid $colorBorder;

        &:before {
            position: absolute;
            top: 0;
            left: 50%;

            width: 50%;
            height: 1px;
            margin-left: -25%;

            content: '';

            background: $colorGreyLight;
        }
        &:after {
            position: absolute;
            bottom: 0;
            left: 50%;

            width: 50%;
            height: 1px;
            margin-left: -25%;

            content: '';

            background: $colorGreyLight;
        }
    }
}
.section--imitate {
    @extend %section;
}
.section {
    @extend %section;
    &--contrast {
        color: color-contrast($colorAccent);
        background-color: $colorAccent;
        .featureBlock-heading {
            color: color-contrast($colorAccent);
        }
        &.job--listings {
            .job--listing {
                border: 1px solid color-contrast($colorAccent);
            }
        }
        &.reviewsRotator {
            .reviewsWrite {
                color: color-contrast($colorBrandPrimary);
            }
        }
        .reviewsWrite {
            color: color-contrast($colorBrandPrimary);
        }
        .video-heading a, .spotlight-heading a {
            color: color-contrast($colorBrandPrimary);
        }
    }
    &--contrastLight {
        color: color-contrast($colorGreyLightest);
        background-color: $colorGreyLightest;
        .featureBlock-heading {
            color: color-contrast($colorGreyLightest);
        }
        &.job--listings {
            .job--listing {
                border: 1px solid color-contrast($colorGreyLightest);
            }
        }
        &.navHorizontal.navSection {
            background-color: $colorGreyLightest;
            a {
                color: color-contrast($colorGreyLightest);
            }
            li {
                a {
                    &:hover {
                        color: color-contrast($colorGreyLightest);
                        &:before {
                            background-color: color-contrast($colorGreyLightest);
                        }
                    }
                }
            }
        }
        &.reviewsRotator {
            .reviews-rating {
                .icon-star, .icon-star-half, .icon-star-alt, .icon-star-half-alt {
                    color: color-contrast($colorGreyLightest);
                    &--white {
                        color: $colorWhite !important;
                    }
                }
            }
        }
        .reviewsWrite {
            color: color-contrast($colorGreyLightest);
        }
        .video-heading a, .spotlight-heading a {
            color: color-contrast($colorGreyLightest);
        }
    }
    &--contrastDark {
        color: color-contrast($colorGreyDarkest);
        background-color: $colorGreyDarkest;
        .featureBlock-heading {
            color: color-contrast($colorGreyDarkest);
        }
        &.job--listings {
            .job--listing {
                border: 1px solid color-contrast($colorGreyDarkest);
            }
        }
        &.navHorizontal.navSection {
            background-color: $colorGreyDarkest;
            a {
                color: color-contrast($colorGreyDarkest);
            }
        }
        .reviewsWrite {
            color: color-contrast($colorGreyDarkest);
        }
        .news-feed-container {
            .news-feed {
                li {
                    h3 {
                        a {
                            color: color-contrast($colorGreyDarkest);
                        }
                    }
                }
            }
        }
        &.reviewsRotator {
            .reviews-rating {
                .icon-star, .icon-star-half, .icon-star-alt, .icon-star-half-alt {
                    color: color-contrast($colorGreyDarkest);
                    &--white {
                        color: $colorWhite !important;
                    }
                }
            }
        }
        .video-heading a, .spotlight-heading a {
            color: color-contrast($colorGreyDarkest);
        }
    }
    &--contrastLightBlue {
        @include contrast-generator($colorEPLightBlue);
    }
    &--contrastMediumBlue {
        @include contrast-generator($colorEPMediumBlue);
    }
    &--contrastDeepBlue {
        @include contrast-generator($colorEPDeepBlue);
    }
    &--contrastPurple {
        @include contrast-generator($colorEPPurple);
    }
    &--contrastTeal {
        @include contrast-generator($colorSRGTeal);
    }
    &--contrastGreen {
        @include contrast-generator($colorEPGreen);
    }
    &--contrastYellow {
        @include contrast-generator($colorEPYellow);
        .spotlight-heading, .spotlight-heading a, .spotlight-content p, .video-heading, .video-heading a, .video-content p {
            color: $colorBrandPrimary;
        }
    }
    &--contrastOrange {
        @include contrast-generator($colorEPOrange);
    }
    &--contrastRed {
        @include contrast-generator($colorEPRed);
    }
    &--contrastGreenBlue {
        @include contrast-generator($colorEPGreen, $colorEPLightBlue);
    }
    &--contrastGreenBlueTop {
        @include contrast-generator($colorEPGreen, $colorEPLightBlue, top);
    }
    &--contrastGreenBlueRight {
        @include contrast-generator($colorEPGreen, $colorEPLightBlue, right);
    }
    &--contrastGreenBlueLeft {
        @include contrast-generator($colorEPGreen, $colorEPLightBlue, left);
    }
    &--contrastBluePurple {
        @include contrast-generator($colorEPLightBlue, $colorEPPurple);
    }
    &--contrastBluePurpleTop {
        @include contrast-generator($colorEPLightBlue, $colorEPPurple, top);
    }
    &--contrastBluePurpleRight {
        @include contrast-generator($colorEPLightBlue, $colorEPPurple, right);
    }
    &--contrastBluePurpleLeft {
        @include contrast-generator($colorEPLightBlue, $colorEPPurple, left);
    }
    &--contrastOrangePurple {
        @include contrast-generator($colorEPOrange, $colorEPPurple);
    }
    &--contrastOrangePurpleTop {
        @include contrast-generator($colorEPOrange, $colorEPPurple, top);
    }
    &--contrastOrangePurpleRight {
        @include contrast-generator($colorEPOrange, $colorEPPurple, right);
    }
    &--contrastOrangePurpleLeft {
        @include contrast-generator($colorEPOrange, $colorEPPurple, left);
    }
    &--contrastRedYellow {
        @include contrast-generator($colorEPRed, $colorEPYellow);
    }
    &--contrastRedYellowTop {
        @include contrast-generator($colorEPRed, $colorEPYellow, top);
    }
    &--contrastRedYellowRight {
        @include contrast-generator($colorEPRed, $colorEPYellow, right);
    }
    &--contrastRedYellowLeft {
        @include contrast-generator($colorEPRed, $colorEPYellow, left);
    }
    &--contrastAccentLighter {
        @include contrast-generator($colorAccentLighter);
    }
    &--contrastAccentDarker {
        @include contrast-generator($colorAccentDarker);
    }
    .aside, .aside--right, .aside--right {
        .featureBlock {
            .featureBlock-heading {
                color: $colorText;
            }
            .featureBlock-figcaption {
                .featureBlock-subHeading, p {
                    color: $colorText;
                }
                .featureBlock-subHeading a {
                    color: $colorLinks;
                }
            }
        }
    }
}