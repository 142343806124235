/* MAIN HEADER
========================================================================== */

.logoMain {
    //max-width: 50%;
    padding: $spacingSm 0;

    text-align: center;
    @include mq(lg) {
        //@include flex(0 0 auto);
        @include flexbox;
        @include align-items(center);

        //max-width: 20%;

        //we need to put a width on the a tag so the logo has something to be max-width:100% OF
        > a {
            display: inline-block;

            width: 100%;
        }
    }

    img {
        @include transition(all 0.2s ease-in-out);
        //adjust this max-height to suit the client logo
        max-height: 80px;
        //shrink the logo to an appropriate size when the header is fixed on scroll
        body[class*='js-headerFixed'] & {
            //max-height:25px;
        }
        // Also Controlled within FooterMain
        body[class*='js-headerFixed'] .footerMain & {
            max-height:60px !important;
        }
    }
}
