@charset 'UTF-8';

/* ICONS
========================================================================== */

/*
* I use fontello.com as it is a very comrehensive icon library and you can build your own font with only the icons you need.
* USAGE: Go to fontello.com, click the dropdown with the lil gear on it, click "import",
* upload the config.json file in Style Library/fonts/,
* then just select/deselect the icons you need and download the webfont.
*
* !!IMPORTANT: These icons codes are sprinkled throughout the stylesheets.
* If you delete any you have to make sure they are not being used. 
*/

@include font-face(fontello,fontello,normal,true);

@mixin iconStyle {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .3em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  //margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  font-size: 110%;
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
%iconStyle {
    @include iconStyle;
}

[class^='icon-'],
[class*=' icon-'] {
    &:before {
        @extend %iconStyle;

        text-decoration: none;
    }

    /*
  * There is a small script that checks to see if there is any text in elements with
  * the icon classes in Script Library/frontEnd.js
  * If there is no text then the script adds the class .icon--empty so we
  * can remove the right margin. Works great on buttons with only an icon, etc.
  */
    &.icon--empty {
        &:before {
            margin-right: 0;
        }
    }
}
// Should make it so pseudo CSS is not read by screen readers
// Needs testing
@media speech {
  [class^='icon-'],
  [class*=' icon-'], a {
    &:before, &:after {
      display:none;
      visibility:hidden;
    }
  }
}
i[class^='icon'],
i[class*=' icon'] {
    text-decoration: none !important;

    &.animate-spin {
        line-height: 1;
    }

    &:before {
        margin: 0;
    }

    &:hover {
        text-decoration: none;
    }
}

/*
* Alignment tweaks
* Sometimes icon fonts don't align horizontally as nice as we'd like so tweak their
* vertical-align property up or down here
*/
.icon-mail, .icon-mail-alt {
    &:before {
        vertical-align:.15em;
    }
}

.icon-arrow-left-down:before { content: '\e800'; } /* '' */
.icon-arrow-up:before { content: '\e801'; } /* '' */
.icon-clipboard:before { content: '\e802'; } /* '' */
.icon-arrow-down:before { content: '\e803'; } /* '' */
.icon-arrow-left:before { content: '\e804'; } /* '' */
.icon-arrow-right:before { content: '\e805'; } /* '' */
.icon-arrow-left-up:before { content: '\e806'; } /* '' */
.icon-arrow-right-up:before { content: '\e807'; } /* '' */
.icon-arrow-right-down:before { content: '\e808'; } /* '' */
.icon-arrow-up-down:before { content: '\e809'; } /* '' */
.icon-arrow-up-down-alt:before { content: '\e80a'; } /* '' */
.icon-arrow-left-right-alt:before { content: '\e80b'; } /* '' */
.icon-arrow-left-right:before { content: '\e80c'; } /* '' */
.icon-arrow-expand-alt2:before { content: '\e80d'; } /* '' */
.icon-arrow-expand-alt:before { content: '\e80e'; } /* '' */
.icon-arrow-condense:before { content: '\e80f'; } /* '' */
.icon-arrow-expand:before { content: '\e810'; } /* '' */
.icon-arrow-move:before { content: '\e811'; } /* '' */
.icon-caret-up:before { content: '\e812'; } /* '' */
.icon-caret-down:before { content: '\e813'; } /* '' */
.icon-caret-left:before { content: '\e814'; } /* '' */
.icon-caret-right:before { content: '\e815'; } /* '' */
.icon-caret-2up:before { content: '\e816'; } /* '' */
.icon-caret-2down:before { content: '\e817'; } /* '' */
.icon-caret-2left:before { content: '\e818'; } /* '' */
.icon-caret-2right:before { content: '\e819'; } /* '' */
.icon-caret-up-alt2:before { content: '\e81a'; } /* '' */
.icon-caret-down-alt2:before { content: '\e81b'; } /* '' */
.icon-caret-left-alt2:before { content: '\e81c'; } /* '' */
.icon-caret-right-alt2:before { content: '\e81d'; } /* '' */
.icon-caret-2up-alt2:before { content: '\e81e'; } /* '' */
.icon-caret-2down-alt2:before { content: '\e81f'; } /* '' */
.icon-caret-2left-alt2:before { content: '\e820'; } /* '' */
.icon-caret-2right-alt2:before { content: '\e821'; } /* '' */
.icon-triangle-up:before { content: '\e822'; } /* '' */
.icon-triangle-down:before { content: '\e823'; } /* '' */
.icon-triangle-left:before { content: '\e824'; } /* '' */
.icon-triangle-right:before { content: '\e825'; } /* '' */
.icon-triangle-up-alt2:before { content: '\e826'; } /* '' */
.icon-triangle-down-alt2:before { content: '\e827'; } /* '' */
.icon-triangle-left-alt2:before { content: '\e828'; } /* '' */
.icon-triangle-right-alt2:before { content: '\e829'; } /* '' */
.icon-arrow-back:before { content: '\e82a'; } /* '' */
.icon-minus:before { content: '\e82b'; } /* '' */
.icon-plus:before { content: '\e82c'; } /* '' */
.icon-close:before { content: '\e82d'; } /* '' */
.icon-check:before { content: '\e82e'; } /* '' */
.icon-minus-alt2:before { content: '\e82f'; } /* '' */
.icon-plus-alt2:before { content: '\e830'; } /* '' */
.icon-close-alt2:before { content: '\e831'; } /* '' */
.icon-check-alt2:before { content: '\e832'; } /* '' */
.icon-zoom-out-alt:before { content: '\e833'; } /* '' */
.icon-zoom-in-alt:before { content: '\e834'; } /* '' */
.icon-search:before { content: '\e835'; } /* '' */
.icon-box-empty:before { content: '\e836'; } /* '' */
.icon-box-selected:before { content: '\e837'; } /* '' */
.icon-minus-box:before { content: '\e838'; } /* '' */
.icon-plus-box:before { content: '\e839'; } /* '' */
.icon-box-checked:before { content: '\e83a'; } /* '' */
.icon-circle-empty:before { content: '\e83b'; } /* '' */
.icon-circle-selected:before { content: '\e83c'; } /* '' */
.icon-stop-alt2:before { content: '\e83d'; } /* '' */
.icon-stop:before { content: '\e83e'; } /* '' */
.icon-pause-alt2:before { content: '\e83f'; } /* '' */
.icon-pause:before { content: '\e840'; } /* '' */
.icon-menu:before { content: '\e841'; } /* '' */
.icon-menu-square-alt2:before { content: '\e842'; } /* '' */
.icon-menu-circle-alt2:before { content: '\e843'; } /* '' */
.icon-ul:before { content: '\e844'; } /* '' */
.icon-ol:before { content: '\e845'; } /* '' */
.icon-adjust-horiz:before { content: '\e846'; } /* '' */
.icon-adjust-vert:before { content: '\e847'; } /* '' */
.icon-doc-alt:before { content: '\e848'; } /* '' */
.icon-docs-alt:before { content: '\e849'; } /* '' */
.icon-pencil:before { content: '\e84a'; } /* '' */
.icon-pencil-edit-alt:before { content: '\e84b'; } /* '' */
.icon-pencil-edit:before { content: '\e84c'; } /* '' */
.icon-folder-alt:before { content: '\e84d'; } /* '' */
.icon-folder-open-alt:before { content: '\e84e'; } /* '' */
.icon-folder-add-alt:before { content: '\e84f'; } /* '' */
.icon-info-alt:before { content: '\e850'; } /* '' */
.icon-attention-oct-alt:before { content: '\e851'; } /* '' */
.icon-attention-circle-alt:before { content: '\e852'; } /* '' */
.icon-attention-alt:before { content: '\e853'; } /* '' */
.icon-question-alt2:before { content: '\e854'; } /* '' */
.icon-question:before { content: '\e855'; } /* '' */
.icon-comment-alt:before { content: '\e856'; } /* '' */
.icon-chat-alt:before { content: '\e857'; } /* '' */
.icon-vol-mute-alt:before { content: '\e858'; } /* '' */
.icon-vol-low-alt:before { content: '\e859'; } /* '' */
.icon-vol-high-alt:before { content: '\e85a'; } /* '' */
.icon-quotes:before { content: '\e85b'; } /* '' */
.icon-quotes-alt2:before { content: '\e85c'; } /* '' */
.icon-clock-alt:before { content: '\e85d'; } /* '' */
.icon-lock-alt:before { content: '\e85e'; } /* '' */
.icon-lock-open-alt:before { content: '\e85f'; } /* '' */
.icon-key-alt:before { content: '\e860'; } /* '' */
.icon-cloud-alt:before { content: '\e861'; } /* '' */
.icon-cloud-upload-alt:before { content: '\e862'; } /* '' */
.icon-cloud-download-alt:before { content: '\e863'; } /* '' */
.icon-image:before { content: '\e864'; } /* '' */
.icon-images:before { content: '\e865'; } /* '' */
.icon-bulb-alt:before { content: '\e866'; } /* '' */
.icon-gift-alt:before { content: '\e867'; } /* '' */
.icon-home-alt:before { content: '\e868'; } /* '' */
.icon-genius:before { content: '\e869'; } /* '' */
.icon-mobile:before { content: '\e86a'; } /* '' */
.icon-tablet:before { content: '\e86b'; } /* '' */
.icon-laptop:before { content: '\e86c'; } /* '' */
.icon-desktop:before { content: '\e86d'; } /* '' */
.icon-camera-alt:before { content: '\e86e'; } /* '' */
.icon-cone-alt:before { content: '\e86f'; } /* '' */
.icon-ribbon-alt:before { content: '\e870'; } /* '' */
.icon-bag-alt:before { content: '\e871'; } /* '' */
.icon-creditcard:before { content: '\e872'; } /* '' */
.icon-cart-alt:before { content: '\e873'; } /* '' */
.icon-paperclip:before { content: '\e874'; } /* '' */
.icon-tag-alt:before { content: '\e875'; } /* '' */
.icon-tags-alt:before { content: '\e876'; } /* '' */
.icon-trash-alt:before { content: '\e877'; } /* '' */
.icon-cursor-alt:before { content: '\e878'; } /* '' */
.icon-mic-alt:before { content: '\e879'; } /* '' */
.icon-compass-alt:before { content: '\e87a'; } /* '' */
.icon-location-alt:before { content: '\e87b'; } /* '' */
.icon-pushpin-alt:before { content: '\e87c'; } /* '' */
.icon-map-alt:before { content: '\e87d'; } /* '' */
.icon-drawer-alt:before { content: '\e87e'; } /* '' */
.icon-toolbox-alt:before { content: '\e87f'; } /* '' */
.icon-book-alt:before { content: '\e880'; } /* '' */
.icon-calendar:before { content: '\e881'; } /* '' */
.icon-film:before { content: '\e882'; } /* '' */
.icon-table:before { content: '\e883'; } /* '' */
.icon-contacts-alt:before { content: '\e884'; } /* '' */
.icon-headphones:before { content: '\e885'; } /* '' */
.icon-lifesaver:before { content: '\e886'; } /* '' */
.icon-piechart:before { content: '\e887'; } /* '' */
.icon-refresh:before { content: '\e888'; } /* '' */
.icon-link-alt:before { content: '\e889'; } /* '' */
.icon-link:before { content: '\e88a'; } /* '' */
.icon-loading:before { content: '\e88b'; } /* '' */
.icon-blocked:before { content: '\e88c'; } /* '' */
.icon-archive-alt:before { content: '\e88d'; } /* '' */
.icon-heart-alt:before { content: '\e88e'; } /* '' */
.icon-star-alt:before { content: '\e88f'; } /* '' */
.icon-star-half-alt:before { content: '\e890'; } /* '' */
.icon-star:before { content: '\e891'; } /* '' */
.icon-star-half:before { content: '\e892'; } /* '' */
.icon-tools:before { content: '\e893'; } /* '' */
.icon-tool:before { content: '\e894'; } /* '' */
.icon-cog:before { content: '\e895'; } /* '' */
.icon-cogs:before { content: '\e896'; } /* '' */
.icon-arrow-up-alt:before { content: '\e897'; } /* '' */
.icon-arrow-down-alt:before { content: '\e898'; } /* '' */
.icon-arrow-left-alt:before { content: '\e899'; } /* '' */
.icon-arrow-right-alt:before { content: '\e89a'; } /* '' */
.icon-arrow-left-up-alt:before { content: '\e89b'; } /* '' */
.icon-arrow-right-up-alt:before { content: '\e89c'; } /* '' */
.icon-arrow-right-down-alt:before { content: '\e89d'; } /* '' */
.icon-arrow-left-down-alt:before { content: '\e89e'; } /* '' */
.icon-arrow-condense-alt:before { content: '\e89f'; } /* '' */
.icon-arrow-expand-alt3:before { content: '\e8a0'; } /* '' */
.icon-arrow-caret-up-alt:before { content: '\e8a1'; } /* '' */
.icon-arrow-caret-down-alt:before { content: '\e8a2'; } /* '' */
.icon-arrow-caret-left-alt:before { content: '\e8a3'; } /* '' */
.icon-arrow-caret-right-alt:before { content: '\e8a4'; } /* '' */
.icon-arrow-caret-2up-alt:before { content: '\e8a5'; } /* '' */
.icon-arrow-caret-2down-alt:before { content: '\e8a6'; } /* '' */
.icon-arrow-caret-2left-alt:before { content: '\e8a7'; } /* '' */
.icon-arrow-caret-2right-alt:before { content: '\e8a8'; } /* '' */
.icon-triangle-up-alt:before { content: '\e8a9'; } /* '' */
.icon-triangle-down-alt:before { content: '\e8aa'; } /* '' */
.icon-triangle-left-alt:before { content: '\e8ab'; } /* '' */
.icon-triangle-right-alt:before { content: '\e8ac'; } /* '' */
.icon-minus-alt:before { content: '\e8ad'; } /* '' */
.icon-plus-alt:before { content: '\e8ae'; } /* '' */
.icon-close-alt:before { content: '\e8af'; } /* '' */
.icon-check-alt:before { content: '\e8b0'; } /* '' */
.icon-zoom-out:before { content: '\e8b1'; } /* '' */
.icon-zoom-in:before { content: '\e8b2'; } /* '' */
.icon-stop-alt:before { content: '\e8b3'; } /* '' */
.icon-menu-square-alt:before { content: '\e8b4'; } /* '' */
.icon-menu-circle-alt:before { content: '\e8b5'; } /* '' */
.icon-doc:before { content: '\e8b6'; } /* '' */
.icon-docs:before { content: '\e8b7'; } /* '' */
.icon-pencil-alt:before { content: '\e8b8'; } /* '' */
.icon-folder:before { content: '\e8b9'; } /* '' */
.icon-folder-open:before { content: '\e8ba'; } /* '' */
.icon-folder-add:before { content: '\e8bb'; } /* '' */
.icon-folder-upload:before { content: '\e8bc'; } /* '' */
.icon-folder-download:before { content: '\e8bd'; } /* '' */
.icon-info:before { content: '\e8be'; } /* '' */
.icon-attention-circle:before { content: '\e8bf'; } /* '' */
.icon-attention-oct:before { content: '\e8c0'; } /* '' */
.icon-attention:before { content: '\e8c1'; } /* '' */
.icon-question-alt:before { content: '\e8c2'; } /* '' */
.icon-comment:before { content: '\e8c3'; } /* '' */
.icon-chat:before { content: '\e8c4'; } /* '' */
.icon-vol-mute:before { content: '\e8c5'; } /* '' */
.icon-vol-low:before { content: '\e8c6'; } /* '' */
.icon-vol-high:before { content: '\e8c7'; } /* '' */
.icon-quotes-alt:before { content: '\e8c8'; } /* '' */
.icon-clock:before { content: '\e8c9'; } /* '' */
.icon-lock:before { content: '\e8ca'; } /* '' */
.icon-lock-open:before { content: '\e8cb'; } /* '' */
.icon-key:before { content: '\e8cc'; } /* '' */
.icon-cloud:before { content: '\e8cd'; } /* '' */
.icon-cloud-upload:before { content: '\e8ce'; } /* '' */
.icon-cloud-download:before { content: '\e8cf'; } /* '' */
.icon-bulb:before { content: '\e8d0'; } /* '' */
.icon-gift:before { content: '\e8d1'; } /* '' */
.icon-home:before { content: '\e8d2'; } /* '' */
.icon-camera:before { content: '\e8d3'; } /* '' */
.icon-mail:before { content: '\e8d4'; } /* '' */
.icon-cone:before { content: '\e8d5'; } /* '' */
.icon-ribbon:before { content: '\e8d6'; } /* '' */
.icon-bag:before { content: '\e8d7'; } /* '' */
.icon-cart:before { content: '\e8d8'; } /* '' */
.icon-tag:before { content: '\e8d9'; } /* '' */
.icon-tags:before { content: '\e8da'; } /* '' */
.icon-trash:before { content: '\e8db'; } /* '' */
.icon-cursor:before { content: '\e8dc'; } /* '' */
.icon-mic:before { content: '\e8dd'; } /* '' */
.icon-compass:before { content: '\e8de'; } /* '' */
.icon-location:before { content: '\e8df'; } /* '' */
.icon-pushpin:before { content: '\e8e0'; } /* '' */
.icon-map:before { content: '\e8e1'; } /* '' */
.icon-drawer:before { content: '\e8e2'; } /* '' */
.icon-toolbox:before { content: '\e8e3'; } /* '' */
.icon-book:before { content: '\e8e4'; } /* '' */
.icon-contacts:before { content: '\e8e5'; } /* '' */
.icon-archive:before { content: '\e8e6'; } /* '' */
.icon-heart:before { content: '\e8e7'; } /* '' */
.icon-profile:before { content: '\e8e8'; } /* '' */
.icon-group:before { content: '\e8e9'; } /* '' */
.icon-grid-sm:before { content: '\e8ea'; } /* '' */
.icon-grid-lg:before { content: '\e8eb'; } /* '' */
.icon-music:before { content: '\e8ec'; } /* '' */
.icon-pause-alt:before { content: '\e8ed'; } /* '' */
.icon-phone:before { content: '\e8ee'; } /* '' */
.icon-upload:before { content: '\e8ef'; } /* '' */
.icon-download:before { content: '\e8f0'; } /* '' */
.icon-facebook:before { content: '\e8f1'; } /* '' */
.icon-twitter:before { content: '\eaef'; } /* '' */
.icon-pinterest:before { content: '\e8f3'; } /* '' */
.icon-googleplus:before { content: '\e8f4'; } /* '' */
.icon-tumblr:before { content: '\e8f5'; } /* '' */
.icon-stumbleupon:before { content: '\e8f6'; } /* '' */
.icon-wordpress:before { content: '\e8f7'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-dribbble:before { content: '\e8f9'; } /* '' */
.icon-vimeo:before { content: '\e8fa'; } /* '' */
.icon-linkedin:before { content: '\e8fb'; } /* '' */
.icon-rss:before { content: '\e8fc'; } /* '' */
.icon-deviantart:before { content: '\e8fd'; } /* '' */
.icon-share:before { content: '\e8fe'; } /* '' */
.icon-myspace:before { content: '\e8ff'; } /* '' */
.icon-skype:before { content: '\e900'; } /* '' */
.icon-youtube:before { content: '\e901'; } /* '' */
.icon-picassa:before { content: '\e902'; } /* '' */
.icon-googledrive:before { content: '\e903'; } /* '' */
.icon-flickr:before { content: '\e904'; } /* '' */
.icon-blogger:before { content: '\e905'; } /* '' */
.icon-spotify:before { content: '\e906'; } /* '' */
.icon-delicious:before { content: '\e907'; } /* '' */
.icon-facebook-circle:before { content: '\e908'; } /* '' */
.icon-twitter-circle:before { content: '\eaf1'; } /* '' */
.icon-pinterest-circle:before { content: '\e90a'; } /* '' */
.icon-googleplus-circle:before { content: '\e90b'; } /* '' */
.icon-tumblr-circle:before { content: '\e90c'; } /* '' */
.icon-stumbleupon-circle:before { content: '\e90d'; } /* '' */
.icon-wordpress-circle:before { content: '\e90e'; } /* '' */
.icon-instagram-circle:before { content: '\e90f'; } /* '' */
.icon-dribbble-circle:before { content: '\e910'; } /* '' */
.icon-vimeo-circle:before { content: '\e911'; } /* '' */
.icon-linkedin-circle:before { content: '\e912'; } /* '' */
.icon-rss-circle:before { content: '\e913'; } /* '' */
.icon-deviantart-circle:before { content: '\e914'; } /* '' */
.icon-share-circle:before { content: '\e915'; } /* '' */
.icon-gmyspace-circle:before { content: '\e916'; } /* '' */
.icon-skype-circle:before { content: '\e917'; } /* '' */
.icon-youtube-circle:before { content: '\e918'; } /* '' */
.icon-picassa-circle:before { content: '\e919'; } /* '' */
.icon-googledrive-circle:before { content: '\e91a'; } /* '' */
.icon-flickr-circle:before { content: '\e91b'; } /* '' */
.icon-blogger-circle:before { content: '\e91c'; } /* '' */
.icon-spotify-circle:before { content: '\e91d'; } /* '' */
.icon-delicious-circle:before { content: '\e91e'; } /* '' */
.icon-facebook-square:before { content: '\e91f'; } /* '' */
.icon-twitter-square:before { content: '\eaf0'; } /* '' */
.icon-pinterest-square:before { content: '\e921'; } /* '' */
.icon-googleplus-square:before { content: '\e922'; } /* '' */
.icon-tumblr-square:before { content: '\e923'; } /* '' */
.icon-stumbleupon-square:before { content: '\e924'; } /* '' */
.icon-wordpress-square:before { content: '\e925'; } /* '' */
.icon-instagram-square:before { content: '\e926'; } /* '' */
.icon-dribbble-square:before { content: '\e927'; } /* '' */
.icon-vimeo-square:before { content: '\e928'; } /* '' */
.icon-linkedin-square:before { content: '\e929'; } /* '' */
.icon-rss-square:before { content: '\e92a'; } /* '' */
.icon-deviantart-square:before { content: '\e92b'; } /* '' */
.icon-share-square:before { content: '\e92c'; } /* '' */
.icon-myspace-square:before { content: '\e92d'; } /* '' */
.icon-skype-square:before { content: '\e92e'; } /* '' */
.icon-youtube-square:before { content: '\e92f'; } /* '' */
.icon-picassa-square:before { content: '\e930'; } /* '' */
.icon-googledrive-square:before { content: '\e931'; } /* '' */
.icon-flickr-square:before { content: '\e932'; } /* '' */
.icon-blogger-square:before { content: '\e933'; } /* '' */
.icon-spotify-square:before { content: '\e934'; } /* '' */
.icon-delicious-square:before { content: '\e935'; } /* '' */
.icon-printer:before { content: '\e936'; } /* '' */
.icon-calculator:before { content: '\e937'; } /* '' */
.icon-building:before { content: '\e938'; } /* '' */
.icon-floppy:before { content: '\e939'; } /* '' */
.icon-drive:before { content: '\e93a'; } /* '' */
.icon-search-2:before { content: '\e93b'; } /* '' */
.icon-id:before { content: '\e93c'; } /* '' */
.icon-id-2:before { content: '\e93d'; } /* '' */
.icon-puzzle:before { content: '\e93e'; } /* '' */
.icon-like:before { content: '\e93f'; } /* '' */
.icon-dislike:before { content: '\e940'; } /* '' */
.icon-mug:before { content: '\e941'; } /* '' */
.icon-currency:before { content: '\e942'; } /* '' */
.icon-wallet:before { content: '\e943'; } /* '' */
.icon-pens:before { content: '\e944'; } /* '' */
.icon-easel:before { content: '\e945'; } /* '' */
.icon-flowchart:before { content: '\e946'; } /* '' */
.icon-data:before { content: '\e947'; } /* '' */
.icon-briefcase:before { content: '\e948'; } /* '' */
.icon-shield:before { content: '\e949'; } /* '' */
.icon-percent:before { content: '\e94a'; } /* '' */
.icon-globe:before { content: '\e94b'; } /* '' */
.icon-globe-2:before { content: '\e94c'; } /* '' */
.icon-target:before { content: '\e94d'; } /* '' */
.icon-hourglass:before { content: '\e94e'; } /* '' */
.icon-balance:before { content: '\e94f'; } /* '' */
.icon-rook:before { content: '\e950'; } /* '' */
.icon-printer-alt:before { content: '\e951'; } /* '' */
.icon-calculator-alt:before { content: '\e952'; } /* '' */
.icon-building-alt:before { content: '\e953'; } /* '' */
.icon-floppy-alt:before { content: '\e954'; } /* '' */
.icon-drive-alt:before { content: '\e955'; } /* '' */
.icon-search-2-alt:before { content: '\e956'; } /* '' */
.icon-id-alt:before { content: '\e957'; } /* '' */
.icon-id-2-alt:before { content: '\e958'; } /* '' */
.icon-puzzle-alt:before { content: '\e959'; } /* '' */
.icon-like-alt:before { content: '\e95a'; } /* '' */
.icon-dislike-alt:before { content: '\e95b'; } /* '' */
.icon-mug-alt:before { content: '\e95c'; } /* '' */
.icon-currency-alt:before { content: '\e95d'; } /* '' */
.icon-wallet-alt:before { content: '\e95e'; } /* '' */
.icon-pens-alt:before { content: '\e95f'; } /* '' */
.icon-easel-alt:before { content: '\e960'; } /* '' */
.icon-flowchart-alt:before { content: '\e961'; } /* '' */
.icon-data-alt:before { content: '\e962'; } /* '' */
.icon-briefcase-alt:before { content: '\e963'; } /* '' */
.icon-shield-alt:before { content: '\e964'; } /* '' */
.icon-percent-alt:before { content: '\e965'; } /* '' */
.icon-globe-alt:before { content: '\e966'; } /* '' */
.icon-mail-alt:before { content: '\e967'; } /* '' */
.icon-ellipsis:before { content: '\e968'; } /* '' */
.icon-visa:before { content: '\e969'; } /* '' */
.icon-mastercard:before { content: '\e96a'; } /* '' */
.icon-discover:before { content: '\e96b'; } /* '' */
.icon-amex:before { content: '\e96c'; } /* '' */
.icon-paypal:before { content: '\e96d'; } /* '' */
.icon-cc-stripe:before { content: '\f1f5'; } /* '' */
.icon-asterisk:before { content: '\e970'; } /* '' */
.icon-link-ext:before { content: '\f08e'; } /* '' */
.icon-sort:before { content: '\e971'; } /* '' */
.icon-sort-down:before { content: '\f0dd'; } /* '' */
.icon-sort-up:before { content: '\f0de'; } /* '' */
.icon-sort-name-up:before { content: '\f15d'; } /* '' */
.icon-sort-name-down:before { content: '\f15e'; } /* '' */
.icon-sort-alt-up:before { content: '\f160'; } /* '' */
.icon-sort-alt-down:before { content: '\f161'; } /* '' */
.icon-sort-number-up:before { content: '\f162'; } /* '' */
.icon-sort-number-down:before { content: '\f163'; } /* '' */
.icon-doc-text-inv:before { content: '\e96e'; } /* '' */
.icon-file:before { content: '\e96f'; } /* '' */
.icon-doc-text:before { content: '\f0f6'; } /* '' */
.icon-doc-inv:before { content: '\f15b'; } /* '' */
.icon-file-pdf:before { content: '\f1c1'; } /* '' */
.icon-file-word:before { content: '\f1c2'; } /* '' */
.icon-file-excel:before { content: '\f1c3'; } /* '' */
.icon-file-powerpoint:before { content: '\f1c4'; } /* '' */
.icon-file-image:before { content: '\f1c5'; } /* '' */
.icon-file-archive:before { content: '\f1c6'; } /* '' */
.icon-file-audio:before { content: '\f1c7'; } /* '' */
.icon-file-video:before { content: '\f1c8'; } /* '' */
.icon-file-code:before { content: '\f1c9'; } /* '' */
