/* BUTTONS
========================================================================== */
/*
* We usually like to keep away from nesting/over specificity, but since we are including input types here
* as well as classes we need to so all styles are available to those input types as well
*/

.btn,
input[type='submit'],
input[type='button'],
input[type='reset'] {
    @include mq(med) {}

    @include font-size($fontSizeBase);
    display: inline-block;

    width: auto; // For button type inputs. Maybe don't mess with this ;)
    margin-bottom: 0; // For button type inputs. Maybe don't mess with this ;)
    padding: $spacingXSm $spacingSm;

    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    text-decoration: none;

    color: color-contrast($btnBg);
    border: 1px solid $btnBorderColor; // For button type inputs. Maybe don't mess with this ;)
    background: $btnBg;

    border-radius: $borderRadius;

    -webkit-appearance: none; //Remove rounded corners on iPhone
    touch-action: manipulation; // Remove 300ms touch delay
    transition: background-color 400ms linear 0ms;

    &:hover {
        color: color-contrast($btnHoverBg);
        border-color: $btnHoverBorderColor;
        background: $btnHoverBg;
    }

    &:active,
    &:focus {
        outline: none;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        cursor: not-allowed;
        pointer-events: none; // Future-proof disabling of clicks

        opacity: .65;
    }

    /* VARIANTS
  ========================================================================== */
    &.btn--inverted {
        color: $btnBg;
        border-color: $btnBg;
        background: color-contrast($btnBg);

        &:hover {
            color: color-contrast($btnBg);
            border-color: $btnHoverBg;
            background: $btnHoverBg;
        }
    }

    &.btn--secondary {
        color: color-contrast($btnSecondaryBg);
        border-color: $btnSecondaryBorderColor;
        background: $btnSecondaryBg;

        &:hover {
            color: color-contrast($btnSecondaryHoverBg);
            border-color: $btnSecondaryHoverBorderColor;
            background: $btnSecondaryHoverBg;
        }
    }

    &.btn--white {
        color: color-contrast($colorWhite);
        border-color: $colorWhite;
        background: $colorWhite;

        &:hover {
            color: color-contrast($btnSecondaryHoverBg);
            border-color: $btnSecondaryHoverBorderColor;
            background: $btnSecondaryHoverBg;
        }
    }

    &.btn--light-blue {
        color: color-contrast($colorEPLightBlue);
        border-color: $colorEPLightBlue;
        background: $colorEPLightBlue;

        &:hover {
            color: color-contrast($btnSecondaryHoverBg);
            border-color: $btnSecondaryHoverBorderColor;
            background: $btnSecondaryHoverBg;
        }
    }

    &.btn--medium-blue {
        color: color-contrast($colorEPMediumBlue);
        border-color: $colorEPMediumBlue;
        background: $colorEPMediumBlue;

        &:hover {
            color: color-contrast($btnSecondaryHoverBg);
            border-color: $btnSecondaryHoverBorderColor;
            background: $btnSecondaryHoverBg;
        }
    }

    &.btn--deep-blue {
        color: color-contrast($colorEPDeepBlue);
        border-color: $colorEPDeepBlue;
        background: $colorEPDeepBlue;

        &:hover {
            color: color-contrast($btnSecondaryHoverBg);
            border-color: $btnSecondaryHoverBorderColor;
            background: $btnSecondaryHoverBg;
        }
    }

    &.btn--grey {
        color: color-contrast($colorEPGrey);
        border-color: $colorEPGrey;
        background: $colorEPGrey;

        &:hover {
            color: color-contrast($btnSecondaryHoverBg);
            border-color: $btnSecondaryHoverBorderColor;
            background: $btnSecondaryHoverBg;
        }
    }

    &.btn--green {
        color: color-contrast($colorEPGreen);
        border-color: $colorEPGreen;
        background: $colorEPGreen;

        &:hover {
            color: color-contrast($btnSecondaryHoverBg);
            border-color: $btnSecondaryHoverBorderColor;
            background: $btnSecondaryHoverBg;
        }
    }

    &.btn--purple {
        color: color-contrast($colorEPPurple);
        border-color: $colorEPPurple;
        background: $colorEPPurple;

        &:hover {
            color: color-contrast($btnSecondaryHoverBg);
            border-color: lighten($colorBrandPrimary, 20%);
            background: lighten($colorBrandPrimary, 20%);
        }
    }

    &.btn--orange {
        color: color-contrast($colorEPOrange);
        border-color: $colorEPOrange;
        background: $colorEPOrange;

        &:hover {
            color: color-contrast($btnSecondaryHoverBg);
            border-color: $btnSecondaryHoverBorderColor;
            background: $btnSecondaryHoverBg;
        }
    }

    &.btn--red {
        color: color-contrast($colorEPRed);
        border-color: $colorEPRed;
        background: $colorEPRed;

        &:hover {
            color: color-contrast($btnSecondaryHoverBg);
            border-color: $btnSecondaryHoverBorderColor;
            background: $btnSecondaryHoverBg;
        }
    }

    &.btn--teal {
        color: color-contrast($colorSRGTeal);
        border-color: $colorSRGTeal;
        background: $colorSRGTeal;

        &:hover {
            color: color-contrast($colorEPLightBlue);
            border-color: $colorEPLightBlue;
            background: $colorEPLightBlue;
        }
    }

    &.btn--yellow {
        color: color-contrast($colorEPYellow);
        border-color: $colorEPYellow;
        background: $colorEPYellow;

        &:hover {
            color: color-contrast($btnSecondaryHoverBg);
            border-color: $btnSecondaryHoverBorderColor;
            background: $btnSecondaryHoverBg;
        }
    }

    /*
   * These buttons will fill the width of their container.
   */
    &.btn--full {
        display: block;

        width: 100%;
    }

    .btn--full+.btn--full {
        margin-top: $spacingXSm;
    }

    /*
  * This button inherits font and other aspects of it's parent naturally
  */
    &.btn--natural {
        padding-right: .5em;
        padding-left: .5em;

        vertical-align: baseline;
        font-size: inherit;
        line-height: inherit;
    }

    /* SIZES
  ========================================================================== */
    /*
   * Button size modifiers.
   */
    &.btn--XSm {
        @include font-size($fontSizeXSm);

        padding: 0 $spacingXSm;
    }

    &.btn--sm {
        @include font-size($fontSizeSm);

        padding: $spacingXSm/2 $spacingSm/2;
    }

    &.btn--lg {
        @include font-size($fontSizeLg);

        padding: $spacingXSm*1.5 $spacingSm*1.5;
    }

    &.btn--XLg {
        @include font-size($fontSizeXLg);

        padding: $spacingSm $spacingBase;
    }

    &.on-map {
        &.hide {
            display: none;
        }

        @include mq(med) {
            display:inline-block;
        }
    }
}