.icon-cards {
    .column {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        img {
            margin-bottom: $spacingBase;
        }
        aside {
            max-width: 400px;
            margin-inline: auto;
            flex: 1;
            display: flex;
            align-items: center;
        }
        .btn {
            font-weight: bold;
        }
    }
}