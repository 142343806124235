/* BANNER Locations
========================================================================== */

.banner--locations {
    .banner-contained {max-width:$maxWidth-wide;}
    .bannerCopy-heading {
        @include font-size(54px);
    }
    .bannerCopy {
        @include font-size(17px);
    }
}