/* MAIN HEADER
========================================================================== */

// Establish common styles for utility and user nav
.navUtil {
    > ul, > div > div > ul {
        margin: 0;
        padding: 0;

        list-style: none;

        text-transform: uppercase;

        > li {
            position: relative;

            @include mq(lg) {
                > a {
                    &:before {
                        @include transition(all .2s ease-in-out);

                        position: absolute;
                        right: 0;
                        bottom: -4px;
                        left: 0;

                        height: 4px;

                        content: '';

                        opacity: 0;
                        background-color: $colorWhite;
                    }
                }
                &:hover {
                    > a:not(.btn) {
                        border-color: $colorGrey;

                        &:before {
                            bottom: 0;

                            opacity: 1;
                        }
                    }
                    > ul {
                        -webkit-transform: scale(1,1);
                            -ms-transform: scale(1,1);
                                transform: scale(1,1);
                    }
                }
            }

            > a {
                position: relative;

                overflow: hidden;

                padding: $spacingXSm $spacingSm;

                text-decoration: none;

                color: color-contrast($colorBrandPrimary);

                font-weight: bold;

                &:hover {
                    text-decoration: none;

                    color: $colorWhite;
                }
            }
            > ul {
                @include transition(all .2s ease-in-out);
                @include flex-direction(column);

                position: absolute;
                z-index: 10;
                top: 100%;
                left: 0;

                min-width: 200px;
                margin: 0;
                padding: $spacingXSm;

                list-style: none;

                -webkit-transform: scale(1,0);
                    -ms-transform: scale(1,0);
                        transform: scale(1,0);
                -webkit-transform-origin: top;
                    -ms-transform-origin: top;
                        transform-origin: top;

                background: rgba($colorBrandPrimaryDark,.95);

                > li {
                    a {
                        @include transition(all .25s ease-in-out);

                        display: block;

                        padding: 0 $spacingXSm;

                        text-decoration: none;

                        color: $colorWhite;

                        &:hover {
                            background: rgba($colorWhite,.15);
                        }
                    }
                }
            }
        }
    }
}

// Specific styling for headerMainNavUtil
.navUtil {

    @include mq(lg) {
        display: block;

        margin-right: auto;

        > ul, > div > div > ul {
            @include flexbox;

            //add arrows after links with babykids
            > li {
                > a {
                    display: block;
                }
                &.hasChildren {
                    > a {
                        &:after {
                            display: inline-block;

                            width: 0;
                            height: 0;
                            margin-left: 5px;

                            content: '';
                            vertical-align: middle;

                            border-top: 4px solid;
                            border-right: 4px solid transparent;
                            border-left: 4px solid transparent;
                        }
                    }
                }
            }
        }
    }
}
