/* LOCATION FINDER
========================================================================== */
.location {
    @extend %flexbox;

    @include flex-direction(column);

    //max-width: $startXXLg;
    margin-right: auto;
    margin-left: auto;
}
.location-content {
    @extend %noPhatBottom;
    @extend %flexbox;

    @include align-items(center);
    @include flex-direction(column);
    @include justify-content(center);

    min-width: 0;
    padding: $spacingBase;

    text-align: center;

    p {
        margin-bottom: $spacingSm;
    }
    //stupid IE11 hack
    * {
        max-width: 100%;
    }
}
.location {
    display: flex;
    width:100%;
}
.location--inner {
    @extend %flexbox;

    @include flex-direction(column);

    max-width: $startXXLg;
    margin-right: auto;
    margin-left: auto;
}
.location-content--hours {
    .location-content--time {
        @extend %flexbox;
        @include justify-content(space-between);
        span:first-of-type {
            margin-right:$spacingBase;
            font-weight: bold;
        }
    }
}
@include mq(med) {
    .location--inner {
        @include flex-direction(row);    
        // max-width:$maxWidth-wide;     
    }   
}
@include mq(lg) {
    .location-content {
        @include flex-direction(column);
        @include justify-content(space-between);
    }
    .location-content--hours {
        padding-right:$spacingBase;
    }
}
.location-content p,
.location-heading,
.location-fakeSelectWrap,
.location-inlineLabel input {
    min-width: 50%;
    margin-bottom: $spacingSm;
}
.location-map {
    @include flex(0 0 auto);

    position: relative;
    display: block;

    iframe {
        width: 100%;
        min-height:100%;
    }

    .location-map-buttons {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
.location-heading {
    @include font-size($h2Size);
}
.location-subheading {
    @include font-size($h5Size);
    margin-top:$spacingLg;
    margin-bottom:$spacingXSm;
    text-align:left;
    display: none;
}
.location-phone,
.location-fax {
    &:before {
        @extend %iconStyle;
    }
}

.location-phone {
    @extend .icon-mobile;
}
.location-fax {
    @extend .icon-printer;
}

@include mq(med) {
    .location {
        @include flex-direction(row);
    }
    .location-content {
        //the 0% is another IE11 hack
        @include flex(1 1 50%);

        min-width: 0;
    }
    .location-map {
        @include flex(1 1 50%);

        min-width: 0;
    }
    .location--mapRight {
        .location-content {
            @include order(1);
        }
        .location-map {
            @include order(2);
        }
    }
}

.location-content--info {
    .phone-mail {
        span {
            display: inline-block;
            height: 50px;
            @include mq(med) {
                height: auto;
            }
        }
    }
    .service-areas {
        text-align: left;
        .title {
            font-weight: bold;
        }
    }
}

.location-details {
    .contained {
        > .row {
            > .column {
                display: flex;
                .location-details-container {
                    background: #fff;
                    border: 1px solid $colorBrandPrimary;
                    border-radius: $borderRadius;
                    padding: $spacingBase;
                    margin-bottom: $spacingLg;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    h3, p {
                        margin-bottom: $spacingSm;
                    }
                    h3, .locationResults-info {
                        flex: 1;
                    }
                    .service_area__hours {
                        display: none;
                    }
                }
            }
        }
    }
}