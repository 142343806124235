/* ISLAND
========================================================================== */

/*
* Padded boxes
*/
%island,
.island,
%islet,
.islet {
    @extend %clearfix;
    @extend %noPhatBottom;
}
%island,
.island {
    padding: $spacingBase;
}
%islet,
.islet {
    padding: $spacingSm;
}
