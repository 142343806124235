.job--listings {
    .job--listing {
        border: 1px solid $colorBrandPrimary;
        border-radius: $borderRadius;
        padding: $spacingBase;
        margin-inline: 0;
        background-color: $colorWhite;
        color: $colorText;
        &:not(:first-of-type) {
            margin-top: $spacingBase;
        }
        .job--details {
            .column:not(:first-of-type) {
                @include mq(lg) {
                    text-align: right;
                }
            }
            @include align-items(center);
            h1, h2, h3, h4, h5, h6 {
                margin-bottom: 0;
            }
            margin-bottom: $spacingBase;
        }
        .job--description-more {
            .job--description p:first-of-type {
                margin-bottom: 0;
            }
            .job--more-information {
                @include flexbox;
                align-items: flex-end;
                margin-top: $spacingBase;
                @include justify-content(flex-end);
                @include mq(lg) {
                    margin-top: 0;
                }
            }
        }
    }
    .search-container {
        position: relative;
        @include flexbox;
        @include flex-direction(column);
        @include mq(lg) {
            @include flex-direction(row);
        }
        label {
            display: block;
        }
        .input-container {
            flex: 1 0 100%;
            @include mq(lg) {
                flex: 1 0 30%;
            }
            display: inline-block;
            padding-block: $spacingSm;
            padding-inline: 0;
            @include mq(lg) {
                padding-right: $spacingSm;
            }
            &.full-width {
                width: 78%;
            }
            input {
                @include font-size(16px);
                height: 42px;
                margin-bottom: 0;
                border: 1px solid $colorGreyLight;
                width: 100%;
                color: $colorText;
            }
            &.radius-container {
                @include mq(lg) {
                    flex: 1 0 auto;
                }    
                select {
                    @include font-size(16px);
                    height: 42px;
                    color: $colorText;
                    border-radius: $borderRadius;
                    margin-bottom: 0;
                    border: 1px solid $colorGreyLight;
                    padding-left: $spacingSm;
                    padding-right: 0;
                }
            }
            p {
                margin-bottom: 0;
            }
        }
        .button-container {
            @include mq(lg) {
                flex: 0 0 auto;
            }
            display: inline-block;
            &:last-of-type() {
                @include mq(lg) {
                    padding-right: 0;
                }
            }
            a {
                position: static;
                padding-inline: $spacingBase;
                height: 41px;
                width: 100%;
            }
        }
    }
    .btn {
        font-weight: bold;
    }

}
.job--rotator {
    .job--listing {
        margin-top: 0 !important;
    }
    .slick-dots {
        margin-top: $spacingBase;
    }
}
.job-details {
    border: 1px solid $colorBrandPrimary;
    border-radius: $borderRadius;
    padding: $spacingBase;
    .socialShare {
        padding-inline: 0;
        .socialShare-heading {
            visibility: hidden;
        }
    }
    .job-details-header {
        @include flexbox;
        @include align-items(center);
        @include justify-content(space-between);
        span {
            @include font-size(18px);
        }
    }
}